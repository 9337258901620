/* eslint-disable camelcase */
import { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { PlainTable } from '@mdc/ui';
import { useTranslation } from 'react-i18next';
import ReactDOMServer from 'react-dom/server';
import { debounce } from 'lodash';
import ContentLoader from 'react-content-loader';
import { UtilsFunctions } from '@mdc/services';

const Leaflet = typeof window !== 'undefined' ? require('leaflet') : null;
const MapContainer =
    typeof window !== 'undefined'
        ? require('react-leaflet').MapContainer
        : null;
const TileLayer =
    typeof window !== 'undefined' ? require('react-leaflet').TileLayer : null;
const Marker =
    typeof window !== 'undefined' ? require('react-leaflet').Marker : null;
const Tooltip =
    typeof window !== 'undefined' ? require('react-leaflet').Tooltip : null;

import 'leaflet/dist/leaflet.css';
import './Network.scss';

const Network = ({ sandboxResources }) => {
    const [isDraggingAllowed, setIsDraggingAllowed] = useState(false);
    const [mapCenter, setMapCenter] = useState([20, 0]);
    const [isClient, setIsClient] = useState(false);
    const [domainResults, setDomainResults] = useState();
    const { t, ready } = useTranslation();

    const tableColumns = [
        {
            Header: t('IP'),
            accessor: 'ip',
        },
        {
            Header: t('Location'),
            accessor: 'location',
        },
        {
            Header: t('For domain'),
            accessor: 'domain',
        },
        {
            Header: t('OSINT providers'),
            accessor: 'verdict',
        },
    ];

    useEffect(() => {
        if (!isClient) {
            setIsClient(true);
        }

        if (!sandboxResources || !sandboxResources.domainResolveResults) {
            return;
        }

        let finalResults = [];

        sandboxResources.results.forEach((item) => {
            if (item?.data?.geo_info) {
                finalResults.push(item);
            }
        });
        setDomainResults(finalResults);

    }, [sandboxResources]);

    if (Leaflet) {
        Leaflet.Icon.Default.imagePath = '../node_modules/leaflet';
        delete Leaflet.Icon.Default.prototype._getIconUrl;
        Leaflet.Icon.Default.mergeOptions({
            iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
            iconUrl: require('leaflet/dist/images/marker-icon.png'),
            shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
        });
    }

    const markerIconHTML = ReactDOMServer.renderToString(
        <span className="markerIcon" />
    );
    const customMarkerIcon = new Leaflet.DivIcon({
        html: markerIconHTML,
    });

    const locations = useMemo(() => {
        if (!domainResults) {
            return;
        }

        return domainResults?.map((item) => {
            return {
                ip: item?.data?.address || null,
                country: item?.data?.geo_info?.country?.name || null,
                city: item?.data?.geo_info?.city?.name || null,
                latitude: item?.data?.geo_info?.location?.latitude || null,
                longitude: item?.data?.geo_info?.location?.longitude || null,
            };
        });
    }, [domainResults]);


    const tableDom = useMemo(() => {
        let locationData = [];
        domainResults?.forEach((item) => {
            if (item?.data?.geo_info) {
                const country = item.data?.geo_info
                    ? ` ${item?.data?.geo_info?.country?.name} ${item?.data?.geo_info?.city?.name}`
                    : '-';
                locationData.push({
                    ip: item.data?.address,
                    location: country,
                    domain: '-',
                    verdict: item?.osintProvider || '-',
                });
            }
        });

        return (
            <PlainTable
                columnsData={tableColumns}
                data={locationData}
                hasSorting={false}
            />
        );
    }, [domainResults]);

    const onResize = debounce(() => {
        if (window.innerWidth > 768) {
            setIsDraggingAllowed(false);
            setMapCenter([20, 0]);
        } else {
            setIsDraggingAllowed(true);
        }
    }, 10);

    useEffect(() => {
        onResize();

        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [isClient]);

    const handleOnMoveEnd = debounce((e) => {
        setMapCenter(e?.target?.getCenter());
    }, 10);

    const leafletMapDOM = useMemo(() => {
        if (typeof window !== 'undefined') {
            const mapTilesURL = '/resources/images/map';

            return <MapContainer
                center={mapCenter}
                zoom={1.55}
                dragging={isDraggingAllowed}
                doubleClickZoom={false}
                scrollWheelZoom={false}
                zoomControl={false}
                useFlyTo={true}
                zoomSnap={1.55}
                trackResize={true}
                onMoveEnd={handleOnMoveEnd}
            >
                <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a>'
                    url={`${mapTilesURL}/{z}/{x}/{y}@2x.png`}
                    noWrap={true}
                />
                {
                    locations?.map((location) => {
                        const position = [location?.latitude ?? 0, location?.longitude ?? 0];
                        return <Marker key={location?.id} position={position} icon={customMarkerIcon}>
                            <Tooltip direction={'top'} offset={[0, 0]} opacity={1}>{<div>
                                {location?.address}
                                <div>IP: {location.ip}</div>
                                <div>Country: {location.country}</div>
                                <div>City: {location.city}</div>
                                <div>Longitude: {location.longitude}</div>
                                <div>Latitude: {location.latitude}</div>
                            </div>
                            }</Tooltip>
                        </Marker>;
                    })
                }
            </MapContainer>;
        }
    }, [locations]);

    if (!ready) {
        return null;
    }

    if (!domainResults) {
        const minRows = 5;
        const maxRows = 12;
        const space = 10;
        const rowHeight = 30;
        const numRows = UtilsFunctions.getRandomInt(minRows, maxRows);
        let keyCounter = 0;

        return <ContentLoader
            className={'contentLoader'}
            speed={1}
            height={numRows * (30 + space)}
            width={'100%'}
            primaryColor='#EDEEF3'
            secondaryColor='#f3f3f3'>
            {Array(numRows).fill('').map((_value, index) => <rect key={keyCounter++} x='0' y={index * (rowHeight + space)} rx='4' ry='4' width={`${Math.random() * 70 + 30}%`} height={rowHeight} />)}
        </ContentLoader>;
    }

    return (
        <Row className="opswatSandboxNetwork">
            <Col xs={12}>
                <p className="h6Mask mt-5">{t('Network')}</p>
                <p>
                    {t(
                        'This map shows places that the sample tried to connect to'
                    )}
                    {'.'}
                </p>
                <div className="map">{domainResults && leafletMapDOM}</div>
            </Col>
            <Col xs={12} className="networkTable mt-3">
                {domainResults && domainResults?.length > 0 && tableDom}
            </Col>
        </Row>
    );
};

Network.propTypes = {
    sandboxResources: PropTypes.Object,
};

export default Network;
