import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import { results, PlainTable, HorizontalTable } from '@mdc/ui';
import { dateTimeService } from '@mdc/services';

import './ExifMetadata.scss';

const { PlainTablePlaceholder } = results;



/* eslint-disable camelcase */
const ExifMetadata = ({ resultsLoading, scanning, exifResults }) => {
    const { t, ready } = useTranslation();
    const getDate = (date) => date.split(' ')[0].split(':').join('.');


    const ExifMetadataData = {
        general: [
            {
                header: ('Creator tool'),
                accessor: 'CreatorTool',
            },
            {
                header: t('Create date'),
                accessor: 'CreateDate',
                parser: (data) => getDate(data.CreateDate)
            },
            {
                header: t('MIME type'),
                accessor: 'MIMEType',
            },
            {
                header: t('FileType extension'),
                accessor: 'FileTypeExtension',
            },
            {
                header: t('File type'),
                accessor: 'FileType',
            },
            {
                header: t('Copyright notice'),
                accessor: 'CopyrightNotice',
            },
            {
                header: t('Credit'),
                accessor: 'Credit',
            },
            {
                header: t('Credit line'),
                accessor: 'CreditLine',
            },
        ],
        details: {
            columnData: [
                {
                    Header: t('Exchangeable Image File Format [EXIF]'),
                    accessor: 'field'
                },
                {
                    Header: t('Details'),
                    accessor: 'data'
                }
            ],
            metaData: [
                {
                    header: t('Megapixels'),
                    accessor: 'Megapixels',
                },
                {
                    header: t('File size'),
                    accessor: 'FileSize',
                },
                {
                    header: t('Image size'),
                    accessor: 'ImageSize',
                },
                {
                    header: t('Image height'),
                    accessor: 'ImageHeight',
                },
                {
                    header: t('Image width'),
                    accessor: 'ImageWidth',
                },
                {
                    header: t('Color components'),
                    accessor: 'ColorComponents',
                },
                {
                    header: t('YCbCr SubSampling'),
                    accessor: 'YCbCrSubSampling',
                },
                {
                    header: t('YCbCr positioning'),
                    accessor: 'YCbCrPositioning',
                },
                {
                    header: t('Bits per sample'),
                    accessor: 'BitsPerSample',
                },
                {
                    header: t('Resolution unit'),
                    accessor: 'ResolutionUnit',
                },
                {
                    header: t('Y resolution'),
                    accessor: 'YResolution',
                },
                {
                    header: t('X resolution'),
                    accessor: 'XResolution',
                },
                {
                    header: t('Instance ID'),
                    accessor: 'InstanceID',
                },
                {
                    header: t('Document ID'),
                    accessor: 'DocumentID',
                },
                {
                    header: t('Marked'),
                    accessor: 'Marked',
                    parser: (data) => data.Marked ? 'True' : 'False'
                },
                {
                    header: t('Modify date'),
                    accessor: 'ModifyDate',
                    parser: (data) => getDate(data.ModifyDate)
                },
                {
                    header: t('Metadata date'),
                    accessor: 'MetadataDate',
                    parser: (data) => dateTimeService.getDateTimeString(data.MetadataDate).replace(/-/g, '.')
                },
                {
                    header: t('Encoding process'),
                    accessor: 'EncodingProcess',
                },
                {
                    header: t('XMP toolkit'),
                    accessor: 'XMPToolkit',
                },
                {
                    header: t('Application record version'),
                    accessor: 'ApplicationRecordVersion',
                },
                {
                    header: t('Envelope record version'),
                    accessor: 'EnvelopeRecordVersion',
                },
                {
                    header: t('EXIF byte order'),
                    accessor: 'EXIFByteOrder',
                },
                {
                    header: t('JFIF version'),
                    accessor: 'JFIFVersion',
                },
                {
                    header: t('Coded character sety'),
                    accessor: 'CodedCharacterSety',
                },
                {
                    header: t('Current IPTC digest'),
                    accessor: 'CurrentIPTCDigest',
                },
            ]
        }
    };

    const aboutExifMetadataDom = (
        <div className='aboutExifMetadata'>
            <h6 className='category'>{t('EXIF Metadata')}</h6>
            <p className='aboutExifMetadata'>
                {t('Exchangeable image file format (EXIF) is a standard for storing metadata information in image files')}
            </p>
        </div>
    );

    const processRawData = (data, meta) => meta.filter((row) => Object.hasOwnProperty.call(data, row.accessor) && (typeof data[row.accessor] === 'number' || typeof data[row.accessor] === 'boolean' || data[row.accessor]))
        .map((row) => {
            let value = row.parser ? row.parser(data) : data[row.accessor];

            if (row.accessor === 'FileType') {
                value = <>
                    {value}
                    <span className='verified'>{t('Verified')}</span>
                </>;
            } else if (row.accessor === 'MIMEType') {
                value = <>
                    {value}
                    <span className='fileTypeBox'>{data.FileType}</span>
                </>;
            }

            return { field: t(row.header), data: value };
        });

    const exifGeneralDom = useMemo(() => {
        if (!exifResults || resultsLoading || scanning) {
            return <>
                <HorizontalTable placeHolderRows={8}/>
                <PlainTablePlaceholder numberOfColumns={2}/>
            </>;
        }

        return <>
            <div className='generalExif'>
                <HorizontalTable data={processRawData(exifResults, ExifMetadataData.general)}/>
            </div>
            <div className='detailsExif'>
                <PlainTable columnsData={ExifMetadataData.details.columnData} data={processRawData(exifResults, ExifMetadataData.details.metaData)}/>
            </div>
        </>;

    }, [exifResults, resultsLoading, scanning, t]);

    if (!ready) {
        return null;
    }

    return <section className='exifMetaData'>
        <Row>
            <Col lg={3} md={3} xs={12}>
                {aboutExifMetadataDom}
            </Col>
            <Col lg={9} md={9} xs={12}>
                {exifGeneralDom}
            </Col>
        </Row>
    </section>;
};

ExifMetadata.propTypes = {
    resultsLoading: PropTypes.bool,
    scanning: PropTypes.string,
    exifResults: PropTypes.object
};

export default ExifMetadata;
