
import { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs, Tab } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';
import FileDetailsSection from './FileDetailsSection';
import { generateFileExtendedDetailsData, FileDetailsData } from './FileDetailsData';
import { UtilsFunctions } from '@mdc/services';

const MultipleTabs = ({ sandboxResources }) => {
    const minRows = 5;
    const maxRows = 12;
    const space = 10;
    const rowHeight = 30;
    const numRows = UtilsFunctions.getRandomInt(minRows, maxRows);
    const { t, ready } = useTranslation();
    const [activeKeys, setActiveKeys] = useState([
        {
            key: 'meta',
            label: t('Meta')
        },
        {
            key: 'oleStreams',
            label: t('OLE Streams')
        },
        {
            key: 'importsEx',
            label: t('Imports/Exports')
        },
        {
            key: 'certInfos',
            label: t('Certificates')
        },
        {
            key: 'resources',
            label: t('Resources')
        },
        {
            key: 'sections',
            label: t('Sections')
        },
        {
            key: 'verinfo',
            label: t('Verinfo')
        },
        {
            key: 'richHeader',
            label: t('Rich Header')
        },
        {
            key: 'header',
            label: t('Header')
        },
        {
            key: 'characteristics',
            label: t('Characteristics')
        },
        {
            key: 'pdbData',
            label: t('Pdb Data')
        }

    ]);
    const [extractedTabActiveKey, setExtractedTabActiveKey] = useState(activeKeys[0].key);

    const keyConditions = [
        { key: 'oleStreams', condition: !sandboxResources?.extendedData?.streams || (sandboxResources?.extendedData?.streams?.length < 1) },
        { key: 'meta', condition: !sandboxResources?.metaData || (sandboxResources?.metaData?.length < 1) },
        { key: 'importsEx', condition: !sandboxResources?.extendedData?.importsEx || (sandboxResources?.extendedData?.importsEx?.length < 1) },
        { key: 'resources', condition: !sandboxResources?.extendedData?.resources || (sandboxResources?.extendedData?.resources?.length < 1) },
        { key: 'sections', condition: !sandboxResources?.extendedData?.sections || (sandboxResources?.extendedData?.sections?.length < 1) },
        { key: 'certInfos', condition: !sandboxResources?.certInfos || (sandboxResources?.certInfos?.length < 1) },
        { key: 'verinfo', condition: !sandboxResources?.extendedData?.verinfo || (sandboxResources?.extendedData?.verinfo?.length < 1) },
        { key: 'richHeader', condition: !sandboxResources?.extendedData?.richHeader || (sandboxResources?.extendedData?.pdbData?.length < 1) },
        { key: 'header', condition: !sandboxResources?.extendedData?.entrypointName || (sandboxResources?.extendedData?.entrypointName?.length < 1) },
        { key: 'pdbData', condition: !sandboxResources?.extendedData?.pdbData || (sandboxResources?.extendedData?.pdbData?.length < 1) },
        { key: 'characteristics', condition: !sandboxResources?.extendedData?.fileCharacteristics || (sandboxResources?.extendedData?.fileCharacteristics?.length < 1) },
    ];

    const proccesImpots = (imports, importsEx) => {
        return imports?.forEach((imprt) => {
            return importsEx?.map((item) => {
                return imprt.imports.map((imp) => {
                    return item.imports.map((it) => {
                        if (it.name === imp.name || imp.name === `${it.name}W`) {
                            it.fileRva = imp.fileRva;
                        }
                    });
                });
            });
        });
    };

    const useEffectCallback = () => {
        if (!isValidSandboxResources(sandboxResources)) {
            return;
        }

        const extendedData = generateFileExtendedDetailsData(sandboxResources);
        processImports(sandboxResources?.extendedData?.imports, extendedData?.importsEx);

        const verinfoObj = convertVerinfoToObject(extendedData?.verinfo);

        updateExtendedDetails(extendedData, verinfoObj);
        updateActiveKeys(keyConditions);
    };

    const isValidSandboxResources = (resources) => {
        return resources && Object.keys(resources).length > 0;
    };

    const processImports = (sandboxImports, extendedImports) => {
        proccesImpots(sandboxImports, extendedImports);
    };

    const convertVerinfoToObject = (verinfo) => {
        return verinfo?.reduce((obj, item) => ({ ...obj, [item.name]: item.value }), {}) || undefined;
    };

    const updateExtendedDetails = (extendedData, verinfoObj) => {
        setExtendedDetails((preState) => ({
            ...preState,
            meta: extendedData.meta || undefined,
            oleStreams: extendedData?.oleStreams || undefined,
            importsEx: extendedData?.importsEx || undefined,
            certInfos: extendedData?.certInfos || undefined,
            resources: extendedData?.resources || undefined,
            sections: extendedData?.sections || undefined,
            verinfo: verinfoObj,
            richHeader: extendedData?.richHeader || undefined,
            header: extendedData?.header || undefined,
            pdbData: extendedData?.pdbData || undefined,
            characteristics: extendedData?.characteristics || undefined,
        }));
    };

    const updateActiveKeys = (keyConditions) => {
        setActiveKeys((prevKeys) => prevKeys.filter((key) => !keyConditions.find((condition) => condition.key === key.key && condition.condition)));
    };

    useEffect(useEffectCallback, [sandboxResources, keyConditions]);

    const [extendedDetails, setExtendedDetails] = useState({
        meta: {},
        oleStreams: [],
        importsEx: [],
        certInfos: [],
        resources: [],
        sections: [],
        verinfo: [],
        richHeader: [],
        pdbData: [],
        characteristics: [],
        headers: []

    });

    const extendedDetailsDOM = (key, extendedDetails) => {
        if (!extendedDetails?.[key] || (Array.isArray(extendedDetails[key]) && extendedDetails[key]?.length === 0) || (typeof extendedDetails[key] === 'object' && Object.keys(extendedDetails[key])?.length === 0)) {
            let keyCounter = 0;
            return <ContentLoader
                className={'contentLoader'}
                speed={1}
                height={numRows * (30 + space)}
                width={'100%'}
                style={{ paddingTop: '20px' }}
                primaryColor='#EDEEF3'
                secondaryColor='#f3f3f3'>
                {Array(numRows).fill('').map((_value, index) => <rect key={keyCounter++} x='0' y={index * (rowHeight + space)} rx='4' ry='4' width={`${Math.random() * 70 + 30}%`} height={rowHeight} />)}
            </ContentLoader>;
        }

        return <FileDetailsSection
            title={''}
            data={extendedDetails[key]}
            columnsData={extendedDetails && FileDetailsData(t).extendedDetails[key]}
            sectionName={key}
        />;
    };

    const activeTabDom = useMemo(() => {
        let keyCounter = 0;
        return activeKeys.map((item) => {
            return <Tab eventKey={item.key} title={item.label} key={keyCounter++}>
                {extendedDetailsDOM(item.key, extendedDetails)}
            </Tab>;
        });
    }, [extendedDetails, activeKeys]);


    if (!ready) {
        return;
    }

    return (
        <Tabs
            activeKey={extractedTabActiveKey}
            onSelect={(key) => { setExtractedTabActiveKey(key); }}
            id="sandboxFileDetailsTab"
            className="mt-2 tabsExtended"
        >
            {activeTabDom}
        </Tabs>
    );
};

MultipleTabs.propTypes = {
    sandboxResources: PropTypes.object,
};

export default MultipleTabs;
