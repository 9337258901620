import { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Col, Tabs, Tab, Button } from 'react-bootstrap';
import { getPropertyOf } from './ExtractedStringsData';
import { Checkbox, TextInput } from '@mdc/ui';
import ExpandableRow from '../../dynamic-analysis/ExpandableRow';
import ContentLoader from 'react-content-loader';
import ExpandableMeta from './ExpandableMeta';
import { UtilsFunctions } from '@mdc/services';

import './ExtractedStrings.scss';

const PLACEHOLDER = 'Search string';

const ExtractedStrings = ({ sandboxResources }) => {
    const [allExtractedStrings, setAllExtractedStrings] = useState({
        extracted: [],
        downloaded: []
    });

    const activeKeys = [
        {
            key: 'extracted',
            label: 'Extracted Strings'
        },
        {
            key: 'downloaded',
            label: 'Downloaded Files'
        }
    ];
    const [searchValue, setSearchValue] = useState('');
    const { t, ready } = useTranslation();
    const minRows = 5;
    const maxRows = 12;
    const space = 10;
    const rowHeight = 30;
    const numRows = UtilsFunctions.getRandomInt(minRows, maxRows);
    const MAX_LENGTH = 100;
    const [showFullText, setShowFullText] = useState({});
    const [isAllExpanded, setIsAllExpanded] = useState(false);

    const toggleShowFullText = (index) => {
        setShowFullText(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    const handleExpandAll = () => {
        setIsAllExpanded(!isAllExpanded);
    };

    const [filters, setFilters] = useState({
        interesting: {
            type: 'key',
            accessor: 'interesting',
            checked: true,
            value: true,
            label: t('Interesting')
        },
        triggeredSignal: {
            type: 'property',
            accessor: 'metaData.triggeredConsumerIDs',
            checked: false,
            label: t('Threat Indicator')
        },
        apiReference: {
            type: 'property',
            accessor: 'metaData.reason',
            checked: false,
            value: 'API_REFERENCE',
            label: t('API Reference')
        },
        UTF8: {
            type: 'property',
            accessor: 'type',
            checked: false,
            value: 'UTF8',
            label: t('UTF8')
        },
    });

    const handleUpdateFilters = (accessor, value) => {
        setFilters((preState) => ({ ...preState, [accessor]: { ...preState[accessor], checked: value } }));
    };

    const searchDOM = useMemo(() => {
        return <TextInput
            onChange={setSearchValue}
            placeholder={PLACEHOLDER}
            initialValue={searchValue}
        />;
    }, []);

    const filterBy = (obj, filtersArray, searchValue) => {
        if (!Array.isArray(obj)) {
            return [];
        }

        if (!filtersArray || filtersArray.length === 0) {
            return obj;
        }

        return obj.filter((item) => {
            const matchesFilter = filtersArray.every((filter) => {
                const objItem = getPropertyOf(item, filters[filter]?.accessor);

                if (filter === 'triggeredSignal') {
                    return item.metaData?.triggeredConsumerIDs !== undefined;
                }

                if (Array.isArray(objItem)) {
                    return objItem.includes(filters[filter]?.value);
                }

                return objItem === filters[filter]?.value;

            });

            if (searchValue.length > 0) {
                const lowerCaseItem = item.str.toLowerCase();
                const lowerCaseSearchValue = searchValue.toLowerCase();
                const startIndex = lowerCaseItem.indexOf(lowerCaseSearchValue);
                return startIndex !== -1 && matchesFilter;
            }

            return matchesFilter;
        });
    };

    const filtersDOM = useMemo(() => {
        return Object.keys(filters)?.map((filter) => {
            return <Checkbox
                isSelected={filters[filter]?.checked}
                onChange={handleUpdateFilters.bind(null, filter, !filters[filter]?.checked)}
                useManualCheck
                key={filter}
            >
                {filters[filter].label}
            </Checkbox>;
        });
    }, [filters]);

    const generateTableData = (strings, searchValue, filters) => {
        const originMap = groupOriginsByType(strings);
        const activeFilters = getActiveFilters(filters);

        return Object.values(originMap).map((origin) => {
            const title = formatTitle(origin.origin.type);
            const filtered = filterBy(origin.references, activeFilters, searchValue);
            const tableData = generateTableRows(filtered, searchValue, origin.origin?.identifier);

            return createExpandableRow(origin, title, tableData, filtered);
        });
    };

    const groupOriginsByType = (strings) => {
        const originMap = {};

        strings.forEach(origin => {
            const key = origin.origin.type;
            if (originMap[key]) {
                originMap[key].references = [...originMap[key].references, ...origin.references];
            } else {
                originMap[key] = { ...origin, references: [...origin.references] };
            }
        });

        return originMap;
    };

    const getActiveFilters = (filters) => {
        return Object.keys(filters).filter((filter) => filters[filter]?.checked === true);
    };

    const formatTitle = (type) => {
        return type.replace('_', ' ').toLowerCase();
    };

    const createExpandableRow = (origin, title, tableData, filtered) => {
        return (
            <ExpandableRow
                key={origin?.origin?.identifier}
                title={<div className='text-capitalize'>{`Origin: ${title}`}</div>}
                tableDom={<Col className='expandableRow'>{tableData}</Col>}
                titleSize='sm'
                className='mt-5'
                extraHeaderFields={[`${filtered?.length}/${origin?.references?.length}`]}
                isExpandedByDefault={isAllExpanded}
            />
        );
    };

    const generateTableRows = (filtered, searchValue, originIdentifier) => {
        if (filtered.length === 0) {
            return <Row className='mt-4 font-weight tableRow'>No strings matching filter</Row>;
        }

        return filtered.map((item, index) => {
            const row = renderRow(item, searchValue, `${originIdentifier}-${index}`);
            return row;
        });
    };

    const renderRow = (item, searchValue, itemKey) => {
        const { beforeMatch, match, afterMatch } = highlightSearchTerm(item.str, searchValue);

        if (match === undefined) {
            return null;
        }

        return (
            <Row key={itemKey} className='mt-4 font-weight tableRow meta'>
                {beforeMatch}
                <span className='highlighted'>{match}</span>
                {renderAfterMatch(afterMatch, itemKey)}
                {item.metaData && <ExpandableMeta meta={item.metaData} />}
            </Row>
        );
    };

    const highlightSearchTerm = (str, searchValue) => {
        const lowerCaseItem = str.toLowerCase();
        const lowerCaseSearchValue = searchValue.toLowerCase();
        const startIndex = lowerCaseItem.indexOf(lowerCaseSearchValue);

        if (startIndex === -1) {
            return { match: undefined };
        }

        const beforeMatch = str.substring(0, startIndex);
        const match = str.substring(startIndex, startIndex + searchValue.length);
        const afterMatch = str.substring(startIndex + searchValue.length);

        return { beforeMatch, match, afterMatch };
    };

    const renderAfterMatch = (afterMatch, itemKey) => {
        if (afterMatch.length > MAX_LENGTH && !showFullText[itemKey]) {
            return (
                <>
                    <span className='afterMatch interesting'>
                        {afterMatch.substring(0, MAX_LENGTH)}...
                    </span>
                    <Button onClick={() => toggleShowFullText(itemKey)} variant='link' className='button'>show more</Button>
                </>
            );
        }

        return (
            <>
                <span className='afterMatch'>
                    {afterMatch}
                </span>
                {afterMatch.length > MAX_LENGTH && (
                    <Button onClick={() => toggleShowFullText(itemKey)} variant='link' className='button'>show less</Button>
                )}
            </>
        );
    };


    useEffect(() => {
        const downloadedFiles = sandboxResources?.fileDownloadResults;
        const stringsData = downloadedFiles?.flatMap(file => file.strings);
        const extractedData = generateTableData(sandboxResources?.strings || [], searchValue, filters);
        const downloadedData = generateTableData(stringsData || [], searchValue, filters);
        setAllExtractedStrings({
            extracted: extractedData,
            downloaded: downloadedData
        });
    }, [sandboxResources, searchValue, filters, showFullText, isAllExpanded]);

    const activeTabDom = useMemo(() => {
        return activeKeys
            .filter(({ key }) =>
                allExtractedStrings[key] && allExtractedStrings[key].length > 0 && allExtractedStrings[key].some(item => item)
            )
            .map(({ key, label }) => (
                <Tab eventKey={key} title={label} key={key}>
                    <div className="p-3">
                        {allExtractedStrings[key]}
                    </div>
                </Tab>
            ));
    }, [allExtractedStrings, activeKeys]);

    const TabsDom = useMemo(() => {
        if (!sandboxResources?.strings) {
            let keyCounter = 0;
            return <ContentLoader
                className={'contentLoader'}
                speed={1}
                height={numRows * (30 + space)}
                width={'100%'}
                primaryColor='#EDEEF3'
                secondaryColor='#f3f3f3'>
                {Array(numRows).fill('').map((_value, index) => <rect key={keyCounter++} x='0' y={index * (rowHeight + space)} rx='4' ry='4' width={`${Math.random() * 70 + 30}%`} height={rowHeight} />)}
            </ContentLoader>;
        }

        return <Tabs
            id="sandboxFileDetailsTab"
            className="mt-2"
        >
            {activeTabDom}
        </Tabs>;
    }, [activeTabDom]);

    if (!ready) {
        return null;
    }

    return <section className='sandboxExtractedStrings'>
        <Row>
            <Col xs={12}>
                <p className='h6Mask mt-5'>{t('Extracted Strings')}</p>
            </Col>
        </Row>

        <Row>
            <Col xs={12} sm={5} md={6} className='filterInput'>
                {searchDOM}
            </Col>
            <Col xs={12} sm={7} md={6} className='sandboxExtractedStringsFilters mt-sm-0 mt-4'>
                {filtersDOM}
            </Col>
        </Row>
        <Row>
            <Col xs={12} className='mt-5 d-flex justify-content-end'>
                <Button variant='outline-secondary' onClick={handleExpandAll}>{isAllExpanded ? t('Collapse All') : t('Expand All')}</Button>
            </Col>
            <Col xs={12} className='mt-5'>
                {TabsDom}
            </Col>
        </Row>
    </section>;
};

export default ExtractedStrings;

ExtractedStrings.propTypes = {
    sandboxResources: PropTypes.object,
};
