/* eslint-disable camelcase */

import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { InternalLink, results, PlainTable } from '@mdc/ui';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import ADDRESS_DATA from './AddressOverviewData';
import { AdvancedOptions, dayjs, Authenticator, Base64, UtilsFunctions } from '@mdc/services';
import { SCAN_ADDRESS_STATUS } from '@mdc/constants';
import ContentLoader from 'react-content-loader';

import './AddressOverview.scss';

const { Alert, ScoreHeader, CodeSnippet } = results;

const AddressOverview = ({ type, id, addressScanResult, addressAnalysis, resultsLoading, fileImage }) => {
    const { t, ready } = useTranslation();
    const apiKey = Authenticator.apikey;

    const scanResults = addressScanResult?.lookup_results || {};

    const scanAddressStatusMap = useMemo(() => ({
        [SCAN_ADDRESS_STATUS.DIRTY]: {
            threatClass: 'detected',
            iconClass: 'icon-close',
            sorting: 0,
        },
        [SCAN_ADDRESS_STATUS.BLACKLISTED]: {
            threatClass: 'detected',
            iconClass: 'icon-close',
            sorting: 0,
        },
        [SCAN_ADDRESS_STATUS.FAILED_TO_SCAN]: {
            assessment: t('Failed to scan'),
            threatClass: 'warning',
            iconClass: 'icon-warning-empty',
            sorting: 1,
        },
        [SCAN_ADDRESS_STATUS.WHITELISTED]: {
            assessment: t('Trustworthy'),
            threatClass: '',
            iconClass: 'icon-checkmark-symbol',
            sorting: 2,
        },
        [SCAN_ADDRESS_STATUS.UNKNOWN]: {
            assessment: t('No threat detected'),
            threatClass: '',
            iconClass: 'icon-checkmark-symbol',
            sorting: 3,
        }
    }), [t]);

    const scoreMetascanDom = useMemo(() => {
        const scanProcessText = resultsLoading ? t('Loading') : '';

        return <ScoreHeader
            category={t('Multiscan Score')}
            score={scanResults.detected_by}
            info={scanResults.detected_by > 0 ? t('Threats detected') : t('No threats detected')}
            detection={scanResults.detected_by > 0 ? 'detected' : 'notDetected'}
            numberOfEngines={scanResults.sources?.length}
            scoreText={t('ENGINES')}
            isProcessing={resultsLoading}
            processImgSrc={fileImage.processing.publicURL}
            processingText={scanProcessText}
        />;
    }, [resultsLoading, scanResults, t]);

    const aboutMultiscanningDom = (
        /*eslint-disable-next-line no-useless-escape*/
        <p dangerouslySetInnerHTML={{ __html: t('Reputation intelligence data collected from multiple vendors. Please read our <a href=\"https://docs.opswat.com/mdcloud/operation/analyzing-ips-with-metadefender-cloud\" rel=\"noopener noreferrer\" target=\"_blank\">documentation</a> for details about interpreting the results') }} />
    );

    const handleRescan = () => {
        AdvancedOptions.updateSelectedOptions('sandbox', 'windows10');
        window.location.reload();
    };

    const rescanBannerDom = useMemo(() => {
        if (addressAnalysis.hasSandboxHeader) {
            return;
        }

        const message = (
            <>
                {t('Adaptive Analysis not performed. ')}
                <InternalLink to={window.location.pathname} onClick={handleRescan}>{t('Rescan')}</InternalLink>
                {t(' with Sandbox for an in-depth analysis.')}
            </>
        );

        return <Alert message={message} type='warning' />;
    }, [addressAnalysis]);

    const tableData = useMemo(() => {
        const sources = scanResults?.sources;
        if (sources) {
            let data = sources.map((source) => {
                const provider = source.provider;
                const {
                    assessment = source.assessment,
                    threatClass,
                    iconClass,
                    sorting
                } = scanAddressStatusMap[source.status] || scanAddressStatusMap[SCAN_ADDRESS_STATUS.UNKNOWN];

                return {
                    result: (<span className={threatClass}><i className={`icon ${iconClass}`} />{assessment}</span>),
                    source: (<span className={threatClass}>{provider}</span>),
                    lastUpdate: source['update_time'] ? dayjs(source['update_time']).format('ll') : '-',
                    sorting
                };
            });

            data.sort((scan1, scan2) => UtilsFunctions.sortHelper(scan1.sorting, scan2.sorting));
            return data;
        }
    }, [scanResults, t]);

    const resultEnginesTableDom = useMemo(() => {
        if (!tableData || !addressScanResult || resultsLoading) {
            const space = 10;
            const rowHeight = 15;
            const numRows = 4;
            let keyCounter = 0;
            return <ContentLoader
                className={'contentLoader'}
                speed={1}
                height={numRows * (30 + space)}
                width={'50%'}
                style={{ paddingTop: '20px' }}
                primaryColor='#EDEEF3'
                secondaryColor='#f3f3f3'
            >
                {Array(numRows).fill('').map((_value, index) => (
                    <rect key={keyCounter++} x='0' y={index * (rowHeight + space)} rx='4' ry='4' width={`${Math.random() * 70 + 30}%`} height={rowHeight} />
                ))}
            </ContentLoader>;
        }

        return <PlainTable
            columnsData={ADDRESS_DATA(t)}
            data={tableData}
            hasSorting={true}
        />;

    }, [tableData, scanResults, resultsLoading]);

    const codeDOM = useMemo(() => {
        return <CodeSnippet apiKey={apiKey} id={encodeURIComponent(Base64.decode(id))} type={type} />;
    }, [apiKey, id, type]);

    if (!ready) {
        return null;
    }

    return <section className='addressOverview'>
        <Row>
            <Col>
                {rescanBannerDom}
            </Col>
        </Row>
        <Row>
            <Col lg={3} md={3} xs={12}>
                {scoreMetascanDom}
                {aboutMultiscanningDom}
            </Col>
            <Col lg={9} md={9} xs={12}>
                {resultEnginesTableDom}
            </Col>
        </Row>
        <Row className='codeSnippetRow'>
            <Col>
                {codeDOM}
            </Col>
        </Row>
    </section>;
};

AddressOverview.propTypes = {
    type: PropTypes.string,
    id: PropTypes.string,
    addressScanResult: PropTypes.object,
    addressAnalysis: PropTypes.object,
    resultsLoading: PropTypes.bool,
    fileImage: PropTypes.object.isRequired,
};

export default AddressOverview;
