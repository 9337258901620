

export default (t) => {
    return [
        {
            Header: t('Result'),
            accessor: 'result'
        },
        {
            Header: t('Action'),
            accessor: 'action'
        },
        {
            Header: t('Object'),
            accessor: 'object_name'
        },
        {
            Header: t('Count'),
            accessor: 'count'
        }
    ];
};
