import { CopyButton } from '@mdc/ui';

const addCopyButton = (data) => <span>{data}<CopyButton icon={'clone'} data={data} /></span>;

const styleText = (data) => {
    let className = 'font-weight-bold';
    if (data === 'Very Low') {
        className = 'badge informational';
    }
    if (data === 'Low') {
        className = 'badge suspicious';
    }
    if (data === 'Medium') {
        className = 'badge likelyMalicious';
    }
    if (data === 'High' || data === 'Very High') {
        className = 'badge malicious';
    }

    return <span className={className}>{data}</span>;
};

export const ProactiveDlpData = (t) => {
    return {
        tableColumns: [
            {
                header: t('Hash'),
                accessor: 'result_template_hash',
                parser: (data) => addCopyButton(data)
            },
            {
                header: t('Certainty'),
                accessor: 'certainty',
                parser: (data) => styleText(data)
            },
            {
                header: t('Anonymization'),
                accessor: 'anonymization',
            },
            {
                header: t('Detection Policy'),
                accessor: 'detection_policy',
            },
            {
                header: t('Crop Embedded Images'),
                accessor: 'crop_embedded_images',
            },
            {
                header: t('Metadata Removal'),
                accessor: 'metadata_removal',
            },
            {
                header: t('Recursive Processing'),
                accessor: 'recursive_processing',
            },
            {
                header: t('Redacted'),
                accessor: 'redact',
            },
            {
                header: t('Watermark'),
                accessor: 'watermark',
            },
        ],
        verdictText: {
            '0': 'Clean',
            '1': 'Found matched data',
            '2': 'Suspicious',
            '3': 'Failed',
            '4': 'Not scanned'
        },
        verdictColor: {
            '0': 'informational',
            '1': 'likelyMalicious',
            '2': 'malicious',
            '3': 'benign',
            '4': 'unknow'
        },
        badgeColor: {
            'Low': 'suspicious',
            'Medium': 'likelyMalicious',
            'High': 'malicious',
            'Very Low': 'informational',
            'Very High': 'critical'
        },
        plainTableColums: [
            {
                Header: t('Hit'),
                accessor: 'hit'
            },
            {
                Header: t('Before'),
                accessor: 'before'
            },
            {
                Header: t('After'),
                accessor: 'after'
            },
            {
                Header: t('Certainty'),
                accessor: 'certainty',
                parser: (data) => styleText(data)
            },
            {
                Header: t('Location'),
                accessor: 'location'
            },
            {
                Header: t('Redacted'),
                accessor: 'tryRedact'
            }
        ]
    };
};
