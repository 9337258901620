/* eslint-disable camelcase */
import { useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { results, PlainTable, HorizontalTable, CopyButton, NotificationContext } from '@mdc/ui';
import { dayjs, fileSizeConvert, dateTimeService } from '@mdc/services';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import classNames from 'classnames';
import PEINFO_DATA from './PeInfoData';
import { flattenDeep } from 'lodash';
import { NOTIFICATION_TYPES } from '@mdc/constants';

import './PeInfo.scss';

const { FileOverview, PlainTablePlaceholder } = results;

const NOTHING_TO_DISPLAY = '-';

const countUniqueValues = (array) => {
    let uniqueValues = {};
    array.forEach((index) => {
        uniqueValues[index] = (uniqueValues[index] || 0) + 1;
    });

    return Object.entries(uniqueValues);
};

const processResourceLangs = (resourceInfo) => {
    if (!resourceInfo) {
        return;
    }

    const resourceLangs = resourceInfo.map((resource) => {
        return resource.resource_ids.map((resourceLang) => {
            return resourceLang.resource_langs;
        });
    });

    const convertToOneArray = flattenDeep(resourceLangs);
    return countUniqueValues(convertToOneArray);
};

const PeInfo = ({ results, resultsLoading, scanning, peInfoResults }) => {
    const { t, ready } = useTranslation();
    const { notify } = useContext(NotificationContext);

    const peInfoRowClassName = classNames('peInfoData');

    const lineDom = <div className="line"></div>;

    const aboutPeInfoDom = (
        <div className='aboutPeInfo'>
            <h6 className='category'>{t('PE Information')}</h6>
            <p className='aboutPeInfo'>
                {t('This information can be used to understand binaries. PE information is particularly helpful because it gives more insight about the files themselves: who the file is signed by, the date the file was compiled, the associated DLLs that get downloaded, etc. These all help to develop better context around the file')}
            </p>
        </div>
    );

    useEffect(() => {
        if (!resultsLoading && !scanning && peInfoResults?.status !== 'processing' && !results?.file_info && results?.additional_info?.includes('peinfo') && peInfoResults && typeof peInfoResults === 'object' && Object.keys(peInfoResults).length <= 0) {
            notify({
                message: t('A server error occurred, and your request could not be completed. Please refresh the page'),
                type: NOTIFICATION_TYPES.CRITICAL
            });
        }
    }, [peInfoResults, resultsLoading, scanning, results]);

    const geenratePeExifData = (optionalHeaders) => {
        const fields = [
            'uninitialized_data_size', 'initialized_data_size', 'checksum',
            'os_version', 'code_size', 'image_size', 'image_version',
            'subsystem_version', 'subsystem', 'entry_point',
            'linker_version', 'pe_type'
        ];

        if (!optionalHeaders) {
            return [];
        }
        return fields.map(field => {
            if (optionalHeaders[field]) {
                return {
                    field: field.split('_').map((word) =>
                        word.charAt(0).toUpperCase() + word.slice(1)
                    ).join(''),
                    data: optionalHeaders[field]
                };
            }
            return null;
        }).filter(item => item !== null);
    };

    const generateHeaderData = (headers) => {
        return headers ? [
            headers?.machine_type ? {
                field: t('Target machine'),
                data: headers?.machine_type
            } : {},
            headers?.compilation_time ? {
                field: t('Compilation timestamp'),
                data: dayjs(headers?.compilation_time).format('YYYY-MM-DD')
            } : {},
            headers?.pointer_to_symbol_table ? {
                field: t('Entry point'),
                data: headers?.pointer_to_symbol_table
            } : {},
            headers?.number_of_sections ? {
                field: t('Number of sections'),
                data: headers?.number_of_sections
            } : {},
        ] : [];
    };

    function constructFirstColumn(fileInfo) {
        return {
            'Vendor': fileInfo?.CompanyName || fileInfo?.company_name || NOTHING_TO_DISPLAY,
            'Product': fileInfo?.ProductName || fileInfo?.product_name || NOTHING_TO_DISPLAY,
            'Copyright': fileInfo?.LegalCopyright || fileInfo?.legal_copyright || NOTHING_TO_DISPLAY,
            'Publisher': fileInfo?.CompanyName || fileInfo?.company_name || NOTHING_TO_DISPLAY,
            'Commenters': fileInfo?.comments || NOTHING_TO_DISPLAY,
            'File type': fileInfo?.FileType || fileInfo?.file_type_description || NOTHING_TO_DISPLAY
        };
    }

    function constructSecondColumn(fileInfo, scanResults) {
        return {
            'File extension': fileInfo?.FileType || fileInfo?.file_type_extension || NOTHING_TO_DISPLAY,
            'Original name': fileInfo?.OriginalFileName || fileInfo?.original_filename || NOTHING_TO_DISPLAY,
            'Internal name': fileInfo?.InternalName || fileInfo?.internal_name || NOTHING_TO_DISPLAY,
            'File version': fileInfo?.FileVersionNumber || fileInfo?.product_version || NOTHING_TO_DISPLAY,
            'File size': fileInfo?.file_size ? fileSizeConvert(fileInfo.file_size) + ' ' + t('[{{fileSize}} bytes]', { fileSize: fileInfo.file_size }) : NOTHING_TO_DISPLAY,
            'First uploaded': fileInfo?.upload_timestamp ? dateTimeService.getDateString(fileInfo.upload_timestamp) : NOTHING_TO_DISPLAY,
            'Scanned': scanResults?.start_time ? dateTimeService.getDateString(scanResults.start_time) : NOTHING_TO_DISPLAY
        };
    }

    const peSectionsDom = useMemo(() => {
        const headerDom = <>
            <h6>{t('PE Sections')}</h6>
            {lineDom}
        </>;

        if (!peInfoResults || resultsLoading || scanning) {
            return (
                <Row className={peInfoRowClassName}>
                    <div className='peSectionsWrapper'>
                        {headerDom}
                        <PlainTablePlaceholder numberOfColumns={6} />
                    </div>
                </Row>
            );
        }

        const sectionHeaders = peInfoResults?.section_headers?.map((item) => {
            item.md5 = <><span className="cellMD5" title={item.md5}>{item.md5} </span> <CopyButton icon={'clone'} data={item.md5} /></>;
            item.entropyRounded = <> <span title={item.entropy}>{item.entropy.toFixed(2)}... </span> <CopyButton icon={'clone'} data={item.entropy} /></>;
            return item;

        });

        if (!sectionHeaders) {
            return;
        }

        return (
            <Row className={peInfoRowClassName}>
                <div className='peSectionsWrapper'>
                    {headerDom}
                    <PlainTable columnsData={PEINFO_DATA(t)} data={sectionHeaders} />
                </div>
            </Row>
        );

    }, [peInfoResults, resultsLoading, scanning, t]);

    const peInfoDataDom = useMemo(() => {
        let headerBasicInfoData;
        let peImportsData;
        let peResourcesData;
        let peResourcesByLang;
        let importedDlls;
        let peExifToolData;
        let firstColumn = [];
        let secondColumn = [];

        if (!peInfoResults || resultsLoading || scanning) {
            firstColumn = undefined;
            secondColumn = undefined;
        } else {
            const { headers, imphash } = peInfoResults;
            const resourceInfo = peInfoResults?.resource_info;
            const optionalHeaders = peInfoResults?.optional_headers;
            const fileInfo = results?.file_info;
            const scanResults = results?.scan_results;

            // PE VS_VERSION_INFO
            const firstColumnData = constructFirstColumn(fileInfo);
            firstColumn.push(firstColumnData);

            const secondColumnData = constructSecondColumn(fileInfo, scanResults);
            secondColumn.push(secondColumnData);

            // PE Headers Basic Information
            headerBasicInfoData = generateHeaderData(headers);

            // PE Imports
            importedDlls = peInfoResults?.imported_dlls ?? [];
            peImportsData = imphash ? [
                {
                    field: t('Imphash'),
                    data: imphash || NOTHING_TO_DISPLAY
                }
            ] : [];

            // PE Resources
            peResourcesData = resourceInfo && resourceInfo.reduce((info, key) => {
                const result = {
                    field: key?.name,
                    data: key?.resource_ids.length ?? NOTHING_TO_DISPLAY
                };
                return info.concat(result);
            }, []) || [];

            // Lang
            peResourcesByLang = resourceInfo && processResourceLangs(resourceInfo).reduce((languages, key) => {
                const languageArr = {
                    field: key[0],
                    data: key[1]
                };

                return languages.concat(languageArr);
            }, []) || [];

            // PE ExifTool
            peExifToolData = geenratePeExifData(optionalHeaders);
        }

        return (
            <>
                <Row className={peInfoRowClassName}>
                    <FileOverview title={t('Version Information')} headlineUnderlined={true} firstColumnData={firstColumn} lastColumnData={secondColumn} />
                </Row>
                <HorizontalTable title={t('PE Header Basic Information')} data={headerBasicInfoData} />
                {peSectionsDom}
                <HorizontalTable title={t('PE Imports')} data={peImportsData} expandableData={importedDlls} />
                <HorizontalTable title={t('Number of PE resources by type')} data={peResourcesData} />
                <HorizontalTable title={t('Number of PE resources by language')} data={peResourcesByLang} />
                <HorizontalTable title={t('Exiftool file metadata')} data={peExifToolData} />
            </>
        );
    }, [peInfoResults, resultsLoading, scanning, results, t]);

    if (!ready) {
        return null;
    }

    return <section className='peInfo'>
        <Row>
            <Col lg={3} md={3} xs={12}>
                {aboutPeInfoDom}
            </Col>
            <Col lg={9} md={9} xs={12} className='peInfoDataTableWrapper'>
                {peInfoDataDom}
            </Col>
        </Row>
    </section>;
};

PeInfo.propTypes = {
    results: PropTypes.object,
    peInfoResults: PropTypes.object,
    resultsLoading: PropTypes.bool,
    scanning: PropTypes.string,
    progress: PropTypes.number,
};

export default PeInfo;
