import { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './ExpandableRow.scss';

const SIZE_TYPES = ['sm', 'md', 'lg'];

const ExpandableRow = ({ isExpandedByDefault = false, title, tableDom, className, titleSize, extraHeaderFields, backgroundColor, extraHeaderPosition = 'end', extraHeaderColor = '' }) => {
    const [expandSymbol, setExpandSymbol] = useState('icon-angle-down');
    const [isExpanded, setIsExpanded] = useState(isExpandedByDefault);

    useEffect(() => {
        setIsExpanded(isExpandedByDefault);
        isExpandedByDefault ? setExpandSymbol('icon-angle-up') : setExpandSymbol('icon-angle-down');
    }, [isExpandedByDefault]);

    const titleClassName = classNames({
        'sm': titleSize === SIZE_TYPES[0],
        'md': titleSize === SIZE_TYPES[1],
        'lg': titleSize === SIZE_TYPES[2],
        'mb-3': !isExpanded,
    }, backgroundColor || '');

    const handleClick = () => {
        setIsExpanded(!isExpanded);
        isExpanded ? setExpandSymbol('icon-angle-down') : setExpandSymbol('icon-angle-up');
    };

    const tagsDom = useMemo(() => {
        if (!extraHeaderFields) {
            return null;
        }

        return <ul className='d-inline-flex ulTags'>
            { extraHeaderFields?.map((field) => <li key={field.id} className={extraHeaderColor}>{field}</li>) }
        </ul>;
    }, [extraHeaderFields, extraHeaderColor]);

    return <div className={`${className} expandableRowWrapper`}>
        <p className={titleClassName} onClick={handleClick}>
            <div className='expandSymbol'>
                <span className='d-flex'>
                    {extraHeaderPosition === 'start' && tagsDom}
                    {title}
                </span>
                <span className='text-right d-flex justify-content-between align-items-center'>
                    {extraHeaderPosition === 'end' && tagsDom}
                    <i className={`icon ${expandSymbol}`}></i>
                </span>
            </div>
        </p>
        {isExpanded && <div>
            {tableDom}
        </div>}
    </div>;
};

ExpandableRow.propTypes = {
    title: PropTypes.object.isRequired,
    tableDom: PropTypes.node.isRequired,
    className: PropTypes.string,
    titleSize: PropTypes.string,
    extraHeaderFields: PropTypes.node,
    backgroundColor: PropTypes.string,
    // 'start', 'end'
    extraHeaderPosition: PropTypes.string,
    extraHeaderColor: PropTypes.any,
    isExpandedByDefault: PropTypes.bool
};

export default ExpandableRow;
