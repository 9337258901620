export default (t) => {
    return [
        {
            Header: t('CVE ID'),
            accessor: 'id'
        },
        {
            Header: t('Severity (index)'),
            accessor: 'severity'
        },
        {
            Header: t('CVSS Score'),
            accessor: 'score'
        },
        {
            Header: t('Last modified'),
            accessor: 'lastModified'
        },
        {
            Header: t('Application'),
            accessor: 'application'
        },
        {
            Header: '',
            accessor: 'meta'
        }
    ];
};
