import { useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { HorizontalTable, PlainTable, TextModal } from '@mdc/ui';
import ExpandableRow from '../../dynamic-analysis/ExpandableRow';
import { useTranslation } from 'react-i18next';

import './FileDetailsSection.scss';

const FileDetailsSection = ({ title, data, columnsData, onlyImages, subTitle, sectionName }) => {
    const { t, ready } = useTranslation();
    const [isModalDisplayed, setIsModalDisplayed] = useState(false);
    const handleCloseModal = () => setIsModalDisplayed(!isModalDisplayed);
    const handleShowModal = () => setIsModalDisplayed(true);
    const getBoolVal = (val) => {
        if (val) {
            return <span className='boolean true'>true</span>;
        }

        return <span className='boolean false'>false</span>;
    };
    const processRawData = (rawdata, meta) => meta.filter((row) => Object.hasOwnProperty.call(rawdata, row.accessor))
        .map((row) => {
            const value = row.parser ? row.parser(rawdata[row.accessor]) : rawdata[row.accessor];
            return { field: row.header, data: value || '-', shouldSanitizeHTML: false };
        });

    const createImageElement = (item) => {
        return <img src={`data:image/png;base64,${item?.base64}`} alt='sandboxCustomImageModal' title='Sandbox Image Modal' />;
    };

    const createImageRow = (item, handleShowModal, isModalDisplayed, handleCloseModal, t) => {
        const imgDOM = createImageElement(item);
        return <div key={item?.id} className='mt-4 d-flex'>
            <img src={`data:image/png;base64,${item?.base64}`} onClick={handleShowModal} alt='sandboxCustomImageModal' title='Sandbox Image Modal' />
            <TextModal
                show={isModalDisplayed}
                onClose={handleCloseModal}
                title={t('File Preview')}
                body={[{ value: imgDOM }]}
                className='sandboxCustomImageModal'
                size={'lg'}
                key={item?.id}
            />
        </div>;
    };

    const createModuleRow = (item, columnsData) => {
        let palinTableData = [];
        item?.imports?.forEach((imp) => {
            const mitreTehniques = imp.allMitreTechniques && imp.allMitreTechniques.map((tehq) => tehq.name).toString() || '-';
            palinTableData.push({ name: imp?.name || '-', fileRva: imp.fileRva || '-', suspicious: getBoolVal(imp.suspicious), ordinal: imp.ordinal || '-', allMitreTechniquesAsString: mitreTehniques });
        });

        return <ExpandableRow
            className='mt-5'
            key={item?.id}
            title={item?.module?.name}
            titleSize={'sm'}
            extraHeaderPosition={'end'}
            tableDom={<PlainTable columnsData={columnsData} data={palinTableData} hasSorting={false} />}
        />;
    };

    const createOtherRow = (item, sectionName, columnsData) => {
        const title = sectionName === 'oleStreams' || sectionName === 'sections' || sectionName === 'pdbData'
            ? item?.oleStream || item?.name || item?.timestamp
            : `<span>${item?.type} <span className='lighterText '> ${item.fileMagicDescription}</span> </span>`;

        const data = processRawData(item, columnsData);
        const tableDom = <HorizontalTable data={data} />;

        return <ExpandableRow
            className='mt-5'
            key={item?.id}
            title={title}
            titleSize={'sm'}
            extraHeaderPosition={'end'}
            tableDom={tableDom}
        />;
    };

    const createRowComponent = (item, sectionName, columnsData) => {
        if (item?.module?.name) {
            return createModuleRow(item, columnsData);
        }
        return createOtherRow(item, sectionName, columnsData);
    };

    const pageDOM = useMemo(() => {
        if (Array.isArray(data)) {
            if (onlyImages) {
                return data.map((item) => createImageRow(item, handleShowModal, isModalDisplayed, handleCloseModal, t));
            }
            return data.map((item) => createRowComponent(item, sectionName, columnsData));
        }

        return columnsData?.map((item) => {
            const extractedValue = item?.parser ? item?.parser(data[item?.accessor])
                : typeof data[item?.accessor] === 'boolean' ? getBoolVal(data[item?.accessor])
                    : data[item?.accessor];
            return (
                <Row key={item?.id}>
                    <Col sm={4} xs={12} className="text lighterText">
                        {item?.header}
                    </Col>
                    <Col sm={8} xs={12}>
                        <span className={`text ${item?.header}`}>
                            {extractedValue}
                        </span>
                    </Col>
                    <Col xl={12}>
                        <div className="line"></div>
                    </Col>
                </Row>
            );
        });

    }, [data, columnsData, isModalDisplayed]);

    if (!ready) {
        return;
    }

    return <div className='fileDetailsSection'>
        {title && <p className='h6Mask'>{title}</p>}
        {subTitle && <p>{subTitle}</p>}
        {title && <div className='line' />}
        {pageDOM}
    </div>;
};

export default FileDetailsSection;

FileDetailsSection.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    data: PropTypes.any,
    columnsData: PropTypes.array,
    onlyImages: PropTypes.bool,
    sectionName: PropTypes.string
};

