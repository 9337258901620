import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { FileResultsService, dateTimeService, UtilsFunctions } from '@mdc/services';
import { results, PlainTable, CveStatus, InternalLink } from '@mdc/ui';
import { Col, Row } from 'react-bootstrap';
import { SCAN_FILE_STATUS, ROUTES } from '@mdc/constants';
import { useTranslation } from 'react-i18next';
import ContentLoader from 'react-content-loader';
import ScanHistoryData from './ScanHistoryData';
import classnames from 'classnames';

import './ScanHistory.scss';

const { ScoreHeader, HistoryGraph } = results;

/* eslint-disable camelcase */
const ScanHistory = ({ results, resultsLoading, scanning, fileImage, progress }) => {
    const { t, ready } = useTranslation();

    const [scanHistory, setScanHistory] = useState(undefined);

    const hash = useMemo(() => results?.file_info?.md5 || results?.file_info?.sha1 || results?.file_info?.sha256, [results]);
    const scanResults = useMemo(() => results?.scan_results ? results.scan_results : {}, [results]);

    useEffect(() => {
        if (resultsLoading || scanning || !hash) {
            FileResultsService.clearScanHistory();
        } else {
            (async () => {
                let scanHistory = await FileResultsService.getScanHistory(hash);

                if (scanHistory === false) {
                    setScanHistory(false);
                } else if (scanHistory) {
                    scanHistory = scanHistory.concat([]).sort(UtilsFunctions.sortScanHistoryDates);

                    setScanHistory(scanHistory.map((scan) => ({
                        ...scan,
                        scanDate: (<InternalLink to={`${ROUTES.results.href}/file/${scan.data_id}/regular/overview`}>{scan.start_time ? dateTimeService.getDateTimeString(scan.start_time) : '-'}</InternalLink>),
                        result: (<InternalLink to={`${ROUTES.results.href}/file/${scan.data_id}/regular/overview`}>
                            <span className={classnames('detectedResult', { detected: scan.total_detected_avs || scan.scan_all_result_i === 1 })}>{scan.total_detected_avs}</span>
                            {'/'}
                            <span>{scan.total_avs}</span>
                        </InternalLink>)
                    })));
                }
            })();
        }
    }, [hash, resultsLoading, scanning]);

    const scoreMetascanDom = useMemo(() => {
        const getScanProcessText = (scanning) => {
            if (scanning === SCAN_FILE_STATUS.inQueue) {
                return t('In Queue');
            } else if (scanning === SCAN_FILE_STATUS.inProgress) {
                return t('In Progress [{{progress}}%]', { progress });
            }

            return '';
        };

        const scanProcessText = resultsLoading ? t('Loading') : getScanProcessText(scanning);

        return <ScoreHeader
            category={t('Scan History')}
            score={scanResults.total_detected_avs}
            info={scanResults.total_detected_avs > 0 || scanResults.scan_all_result_i === 1 ? t('Threats detected') : t('No threats detected')}
            detection={scanResults.total_detected_avs > 0 || scanResults.scan_all_result_i === 1 ? 'detected' : 'notDetected'}
            numberOfEngines={scanResults.total_avs}
            scoreText={t('ENGINES')}
            isProcessing={resultsLoading || !!scanning}
            processImgSrc={fileImage.processing.publicURL}
            processingText={scanProcessText}
        />;
    }, [resultsLoading, scanning, progress, scanResults, fileImage, t]);

    const textPlaceholder = <ContentLoader
        speed={1}
        height={14}
        width={200}
        primaryColor="#EDEEF3"
        secondaryColor="#f3f3f3">
        <rect x="0" y="0" rx="4" ry="4" width={200} height={14} />
    </ContentLoader>;

    const scanSummaryDom = useMemo(() => {
        const getTextDom = (scanHistory) => {
            if (scanHistory?.length > 1) {
                return t('This file has been scanned {{times}} times', { times: scanHistory.length });
            }

            return scanHistory?.length === 1 ? t('This file has been scanned 1 time') : textPlaceholder;
        };

        return <p className='scanSummary'>
            {getTextDom(scanHistory)}
        </p>;
    }, [scanHistory, t]);

    const tableDom = useMemo(() => {
        if (scanHistory) {
            const revert = scanHistory.concat([]).reverse();
            return <PlainTable data={revert} columnsData={ScanHistoryData(t)} hasPagination={true} />;
        }

        const minRows = 5;
        const maxRows = 12;
        const space = 10;
        const rowHeight = 30;
        const numRows = UtilsFunctions.getRandomInt(minRows, maxRows);
        let keyCounter = 0;

        return <ContentLoader
            className={'contentLoader'}
            speed={1}
            height={numRows * (30 + space)}
            width={'100%'}
            style={{ paddingTop: '20px' }}
            primaryColor='#EDEEF3'
            secondaryColor='#f3f3f3'>
            {Array(numRows).fill('').map((_value, index) => <rect key={keyCounter++} x='0' y={index * (rowHeight + space)} rx='4' ry='4' width={`${Math.random() * 70 + 30}%`} height={rowHeight} />)}
        </ContentLoader>;
    }, [scanHistory]);

    if (!ready) {
        return null;
    }

    return <div className='ScanHistoryPage'>
        <Row>
            {scanHistory === false &&
                <Col>
                    <CveStatus isUnderAnalysis={true} message={t('Scan history data for this file has not been generated yet')} />
                </Col>
            }
            {scanHistory !== false &&
                <>
                    <Col lg={3} md={3} xs={12}>
                        {scoreMetascanDom}
                        {scanSummaryDom}
                    </Col>
                    <Col lg={9} md={9} xs={12}>
                        <HistoryGraph scanHistory={scanHistory} />
                        {tableDom}
                    </Col>
                </>
            }
        </Row>
    </div>;
};

ScanHistory.propTypes = {
    results: PropTypes.object,
    resultsLoading: PropTypes.bool,
    scanning: PropTypes.string,
    progress: PropTypes.number,
    fileImage: PropTypes.object.isRequired,
};


export default ScanHistory;
