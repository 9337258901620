export default [
    {
        Header: 'Result',
        accessor: 'result'
    },
    {
        Header: 'Engine',
        accessor: 'engine'
    },
    {
        Header: 'Last update',
        accessor: 'lastUpdate'
    }
];
