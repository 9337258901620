import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { results, VoteButton, InternalLink } from '@mdc/ui';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import VoteResults from './VoteResults';
import classNames from 'classnames';
import ContentLoader from 'react-content-loader';

import './Community.scss';

const { ScoreHeader, CommunityGraph } = results;

const Community = (
    {
        type,
        id,
        action,
        resultsLoading,
        scanning,
        upVotes,
        downVotes,
        handleUpVote,
        handleDownVote,
        alreadyVoted,
        disabledVote,
        isSignedIn,
        fileImage
    }
) => {
    const { t, ready } = useTranslation();

    const communityRowClass = classNames({
        'noVotes': downVotes === 0 && upVotes === 0
    });

    const voteScore = useMemo(() => {
        if (upVotes || downVotes) {
            return Math.round(downVotes * 100 / (downVotes + upVotes));
        }
        return undefined;
    }, [upVotes, downVotes, resultsLoading, scanning]);

    const scoreMetascanDom = useMemo(() => {
        const getScoreInfo = (voteScore) => {
            if (voteScore !== undefined) {
                return {
                    score: 100 - voteScore,
                    badge: t('Clean'),
                    badgeColor: 'success'
                };
            }

            return {
                score: -1,
                badge: undefined,
                badgeColor: null
            };
        };

        return <ScoreHeader
            category={t('Community Insight')}
            score={voteScore >= 50 ? voteScore : getScoreInfo(voteScore).score}
            badge={voteScore >= 50 ? t('Malicious') : getScoreInfo(voteScore).badge}
            badgeColor={voteScore >= 50 ? 'danger' : getScoreInfo(voteScore).badgeColor}
        />;
    }, [voteScore, type, id, action, t]);

    const aboutCommunityInsight = useMemo(() => {
        return <>
            <p className='aboutCommunity'>{t('Our users come together to contribute to greater security through active voting')}</p>

            <span className='learnMoreHref'>
                <InternalLink to="/leaderboard">{t('View leaderboards')}</InternalLink>
            </span>
        </>;
    }, [t]);

    const communityGraphDom = useMemo(() => {
        return <Row className='communityGraphRow'>
            <Col className='communityGraphWrapper'>
                <CommunityGraph upVotes={upVotes} downVotes={downVotes} id={id} action={action} voteScore={voteScore}/>
            </Col>
        </Row>;
    }, [upVotes, downVotes]);

    const upVoteResultsDom = useMemo(() => {
        return <VoteResults
            icon='icon-thumbs-up'
            type={t('Clean')}
            description={t('Legitimate file with no malicious behavior')}
            score={upVotes}
            handleVote={handleUpVote}
            alreadyVoted={alreadyVoted}
            disabledVote={disabledVote}
            isSignedIn={isSignedIn}
            voteType='up'
        />;
    }, [upVotes, alreadyVoted, disabledVote, isSignedIn, handleUpVote, t]);

    const downVoteResultsDom = useMemo(() => {
        return <VoteResults
            icon='icon-thumbs-down'
            type={t('Malicious')}
            description={t('Dangerous file which might harm your computer, leak data or has unintended behavior')}
            score={downVotes}
            handleVote={handleDownVote}
            alreadyVoted={alreadyVoted}
            disabledVote={disabledVote}
            isSignedIn={isSignedIn}
            voteType='down'
        />;
    }, [upVotes, alreadyVoted, disabledVote, isSignedIn, handleDownVote, t]);

    const upVoteButton = useMemo(() => {
        return <VoteButton
            isSignedIn={isSignedIn}
            handleVote={handleUpVote}
            alreadyVoted={alreadyVoted}
            disabled={disabledVote}
            type={'up'}
            buttonText={t('Clean')}
        />;
    }, [upVotes, alreadyVoted, disabledVote, isSignedIn, handleUpVote, t]);

    const downVoteButton = useMemo(() => {
        return <VoteButton
            isSignedIn={isSignedIn}
            handleVote={handleDownVote}
            alreadyVoted={alreadyVoted}
            disabled={disabledVote}
            type={'down'}
            buttonText={t('Malicious')}
        />;
    }, [downVotes, alreadyVoted, disabledVote, isSignedIn, handleDownVote, t]);

    const communityDom = useMemo(() => {
        if (resultsLoading) {
            const space = 10;
            const rowHeight = 30;
            const numRows = 6;
            let keyCounter = 0;
            return <ContentLoader
                className={'contentLoader'}
                speed={1}
                height={numRows * (30 + space)}
                width={'100%'}
                style = {{ paddingTop: '20px' }}
                primaryColor='#EDEEF3'
                secondaryColor='#f3f3f3'>
                {Array(numRows).fill('').map((_value, indexName) => <rect key={keyCounter++} x='0' y={indexName * (rowHeight + space)} rx='4' ry='4' width={`${Math.random() * 70 + 30}%`} height={rowHeight} />)}
            </ContentLoader>;
        }

        if (upVotes === 0 && downVotes === 0 && !resultsLoading) {
            return <Col className='text-center'>
                <img src={fileImage.noVotes.publicURL} alt='Cast your vote' title='Votes'/>
                <h1 className='m-0'>{t('Cast your vote now!')}</h1>
                <p className='p-0 m-0'>{t('Be the first to vote on this file')}</p>
                <div className='voteButtons'>
                    {upVoteButton} {downVoteButton}
                </div>
            </Col>;
        }

        return <>
            <Col lg={4} md={4} xs={12} className='aboutCommunityCol'>
                { scoreMetascanDom }
                { aboutCommunityInsight }
            </Col>
            <Col lg={8} md={8} xs={12}>
                {upVoteResultsDom}
                {communityGraphDom}
                {downVoteResultsDom}
            </Col>
        </>;
    }, [upVotes, downVotes, alreadyVoted, disabledVote, isSignedIn, handleUpVote, t, resultsLoading]);

    if (!ready) {
        return null;
    }

    return <section className='community'>
        <Row className={communityRowClass}>
            { communityDom }
        </Row>
    </section>;
};

Community.propTypes = {
    type: PropTypes.string,
    id: PropTypes.string,
    action: PropTypes.string,
    resultsLoading: PropTypes.bool,
    scanning: PropTypes.string,
    upVotes: PropTypes.number,
    downVotes: PropTypes.number,
    handleUpVote: PropTypes.func.isRequired,
    handleDownVote: PropTypes.func.isRequired,
    alreadyVoted: PropTypes.string,
    disabledVote: PropTypes.bool,
    isSignedIn: PropTypes.bool.isRequired,
    fileImage: PropTypes.object.isRequired
};

export default Community;
