/* eslint-disable camelcase */
/* eslint-disable no-useless-escape */
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { results, PlainTable, PDFExport } from '@mdc/ui';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { SCAN_FILE_STATUS, ENGINE_STATUS, RESULT_TEXTS } from '@mdc/constants';
import MULTISCAN_DATA from './MultiscanData';
import { dayjs, UtilsFunctions } from '@mdc/services';
import ContentLoader from 'react-content-loader';

import './Multiscan.scss';

const { Alert, ScoreHeader } = results;

const Multiscan = ({ type, id, action, results, resultsLoading, scanning, fileImage, progress }) => {
    const { t, ready, i18n } = useTranslation();

    const resultTexts = useMemo(() => RESULT_TEXTS(t), [t]);

    const scanResults = results?.scan_results ? results.scan_results : {};
    const extracted = results?.extracted_files?.files_in_archive;
    const scanDetails = scanResults?.scan_details;
    const fileInfo = results?.file_info.display_name;
    const infoFile = results?.file_info;
    const extractedFiles = results?.extracted_files?.files_in_archive;
    const sanitizationResultsDetails = results?.process_info?.post_processing?.sanitization_details?.details;
    const dlpResults = results?.dlp_info?.hits;

    const scanScoreInfo = useMemo(() => {
        if (scanResults.total_detected_avs > 0 || scanResults.scan_all_result_i === 1) {
            return 'Threats detected';
        }
        if (scanResults.scan_all_result_i === 15 || scanResults?.scan_all_result_a === 'Aborted') {
            return 'No multiscan performed';
        } return 'No threats detected';
    }, [scanResults]);

    const detection = useMemo(() => {
        if (scanResults.total_detected_avs > 0 || scanResults.scan_all_result_i === 1) {
            return 'detected';
        }
        if (scanResults.scan_all_result_i === 15 || scanResults?.scan_all_result_a === 'Aborted') {
            return 'notPerformed';
        } return 'notDetected';
    }, [scanResults]);

    const infectedArchiveAlert = useMemo(() => {
        const extractedDetectedBy = extracted && extracted.reduce((prevEngine, currentEngine) => (
            prevEngine.detected_by < currentEngine.detected_by ? currentEngine : prevEngine
        ))?.detected_by;
        const archiveDetectedBy = scanResults?.total_detected_avs;

        if (extractedDetectedBy > 0 && archiveDetectedBy > 0) {
            const message = t('The archive was detected by {{archiveNumberOfEngines}} engine(s) as infected, and contains files detected as infected by up to {{numberOfEngines}} engine(s)', { archiveNumberOfEngines: archiveDetectedBy, numberOfEngines: extractedDetectedBy });
            return <Alert message={message} type='warning' />;
        }

        return null;
    }, [scanResults, extracted, t]);

    const archiveInfectedAlert = useMemo(() => {
        if (!resultsLoading && !scanning && results?.scan_results?.total_detected_avs === 0 && results?.extracted_files?.files_in_archive?.some((file) => file.scan_result_i === 1)) {
            return <Alert message={t('The archive has been marked as infected because the files in the archives are detected as infected')} type='warning' />;
        }
    }, [resultsLoading, scanning, results, t]);

    const scoreMetascanDom = useMemo(() => {
        const getScanProcessText = (scanning) => {
            if (scanning === SCAN_FILE_STATUS.inQueue) {
                return t('In Queue');
            } else if (scanning === SCAN_FILE_STATUS.inProgress) {
                return t('In Progress [{{progress}}%]', { progress });
            }

            return '';
        };

        const scanProcessText = resultsLoading ? t('Loading') : getScanProcessText(scanning);
        return <ScoreHeader
            category={t('Metascan Multiscan')}
            score={scanResults.total_detected_avs}
            info={scanScoreInfo}
            detection={detection}
            numberOfEngines={scanResults.total_avs}
            scoreText={t('ENGINES')}
            isProcessing={resultsLoading || !!scanning}
            processImgSrc={fileImage.processing.publicURL}
            processingText={scanProcessText}
        />;
    }, [resultsLoading, scanning, progress, scanResults, type, id, action, t]);

    const aboutMultiscanningDom = useMemo(() => (
        <>
            <p className='aboutMultiscanning' dangerouslySetInnerHTML={{ __html: t('Multiscanning, is an advanced threat detection and prevention technology that increases detection rates, decreases outbreak detection times and provides resiliency to anti-malware vendor issues.<br/><br/> OPSWAT pioneered the concept of multi-scanning files with over 30 anti-malware engines available to deliver enhanced protection from a variety of cyber threats') }} />
            {/*eslint-disable-next-line no-useless-escape*/}
            <span className='learnMoreHref' dangerouslySetInnerHTML={{ __html: t('<a href=\"https://www.opswat.com/technologies/multiscanning\" target=\"_blank\">Learn more about Multiscanning</a>') }} />
        </>
    ), [t]);

    const tableData = useMemo(() => {
        if (!scanDetails) {
            return;
        }

        let data = Object.keys(scanDetails).map((engineName) => {
            let threatName, threatClass, iconClass, sorting;

            switch (scanDetails[engineName].scan_result_i) {
                case ENGINE_STATUS.INFECTED:
                    threatName = scanDetails[engineName].threat_found?.trim() || resultTexts.resultStatus.unknown_threat;
                    threatClass = 'detected';
                    iconClass = 'icon-close';
                    sorting = 0;
                    break;

                case ENGINE_STATUS.SUSPICIOUS:
                    threatName = t('Suspicious');
                    threatClass = 'suspicious';
                    iconClass = 'icon-warning-empty';
                    sorting = 1;
                    break;

                case ENGINE_STATUS.NO_THREAT:
                    threatName = t('No threat detected');
                    threatClass = '';
                    iconClass = 'icon-checkmark-symbol';
                    sorting = 2;
                    break;

                case ENGINE_STATUS.UNSUPPORTED_FILE_TYPE:
                    threatName = t('Unsupported file type');
                    threatClass = 'suspicious';
                    iconClass = 'icon-warning-empty';
                    sorting = 3;
                    break;

                default:
                    threatName = Object.prototype.hasOwnProperty.call(resultTexts.resultStatus, `i${scanDetails[engineName].scan_result_i}`)
                        ? resultTexts.resultStatus[`i${scanDetails[engineName].scan_result_i}`]
                        : 'No threat detected';
                    threatClass = '';
                    iconClass = 'icon-checkmark-symbol';
                    sorting = 2;
                    break;
            }

            if (scanResults?.scan_all_result_i === 15) {
                threatName = t('Password Protected Document');
                threatClass = 'notPerformed';
                iconClass = 'icon-warning-empty';
                sorting = 4;
            }

            dayjs.locale(i18n?.language?.split('-')[0] || 'en');
            return {
                result: (<span className={threatClass}><i className={`icon ${iconClass}`} />{threatName}</span>),
                engine: (<span className={threatClass}>{engineName}</span>),
                lastUpdate: !scanDetails[engineName]['def_time'] || new Date(scanDetails[engineName]['def_time']).getTime() === 0 ? '-' : dayjs(scanDetails[engineName]['def_time']).format('ll'),
                sorting
            };
        });

        data.sort((scan1, scan2) => UtilsFunctions.sortHelper(scan1.sorting, scan2.sorting));
        return data;
    }, [scanDetails, scanResults, results, t, i18n, resultTexts]);

    const resultEnginesTableDom = useMemo(() => {

        if (!tableData || !results || resultsLoading || scanning) {
            const minRows = 5;
            const maxRows = 12;
            const space = 10;
            const rowHeight = 30;
            const numRows = UtilsFunctions.getRandomInt(minRows, maxRows);
            let keyCounter = 0;

            return <ContentLoader
                className={'contentLoader'}
                speed={1}
                height={numRows * (30 + space)}
                width={'100%'}
                primaryColor='#EDEEF3'
                secondaryColor='#f3f3f3'>
                {Array(numRows).fill('').map((_value, index) => <rect key={keyCounter++} x='0' y={index * (rowHeight + space)} rx='4' ry='4' width={`${Math.random() * 70 + 30}%`} height={rowHeight} />)}
            </ContentLoader>;
        }

        if (tableData.length === 0) {
            return aboutMultiscanningDom;
        }

        return <PlainTable
            columnsData={MULTISCAN_DATA}
            data={tableData}
            hasSorting={true}
        />;

    }, [tableData, results, resultsLoading, scanning, t]);

    if (!ready) {
        return null;
    }

    return <section className='multiscan'>
        {archiveInfectedAlert}
        {infectedArchiveAlert}
        {infoFile && scanResults && fileInfo && results && (
            <Row>
                <Col>
                    <PDFExport
                        aditionalData={infoFile}
                        data={scanResults}
                        types={['pdf']}
                        fileName={fileInfo}
                        results={results}
                        extractedFiles={extractedFiles}
                        sanitizationResultsDetails={sanitizationResultsDetails}
                        dlpResults={dlpResults}
                    />
                </Col>
            </Row>
        )}
        <Row>
            <Col lg={4} md={4} xs={12}>
                {scoreMetascanDom}
                {tableData?.length !== 0 && aboutMultiscanningDom}
            </Col>
            <Col lg={8} md={8} xs={12} className='tableColumn'>
                {resultEnginesTableDom}
            </Col>
        </Row>
    </section>;
};

Multiscan.propTypes = {
    type: PropTypes.string,
    id: PropTypes.string,
    action: PropTypes.string,
    results: PropTypes.object,
    resultsLoading: PropTypes.bool,
    scanning: PropTypes.string,
    progress: PropTypes.number,
    fileImage: PropTypes.object.isRequired
};

export default Multiscan;
