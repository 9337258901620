/* eslint-disable camelcase */
import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Button } from 'react-bootstrap';
import { ProcessesDiagram, HorizontalTable, Checkbox } from '@mdc/ui';
import { useTranslation } from 'react-i18next';
import ExpandableRow from '../../dynamic-analysis/ExpandableRow';
import PROC_DATA from './ProcessesData';
import ContentLoader from 'react-content-loader';
import { UtilsFunctions } from '@mdc/services';


import './Processes.scss';

const Processes = ({ sandboxResources }) => {
    const { t, ready } = useTranslation();
    const [isAllExpanded, setIsAllExpanded] = useState(false);
    const handleExpandAll = () => {
        setIsAllExpanded(!isAllExpanded);
    };
    const [isInteresting, setIsInteresting] = useState(false);

    const getProcessedRawDataValue = (value) => {
        if (Array.isArray(value)) {
            return value.join(', ');
        }

        if (typeof value === 'object') {
            return JSON.stringify(value);
        }

        return value;
    };

    const processRawData = (data, meta) => meta.filter((row) => Object.hasOwnProperty.call(data, row.accessor) && (data[row.accessor]) || (data.additionalInformation[row.accessor]))
        .map((row) => {
            let value;

            if (row.parser) {
                value = row.parser(data[row.accessor]);
            } else if (data[row.accessor]) {
                value = data[row.accessor];
            } else {
                value = data.additionalInformation[row.accessor];
            }

            value = getProcessedRawDataValue(value);

            return { field: row.header, data: value, shouldSanitizeHTML: false };
        });

    const expandableRowDom = (emulationData) => {
        return (
            <HorizontalTable data={processRawData(emulationData, PROC_DATA(t))} />
        );
    };

    const expandableTableDOM = useMemo(() => {
        const processesResults = sandboxResources;
        if (!processesResults || processesResults?.emulationData?.length === 0 || !processesResults.emulationData || !Array.isArray(processesResults.emulationData)) {
            return;
        }

        const filteredResults = isInteresting ? processesResults.emulationData.filter((item) => item.interesting) : processesResults.emulationData;

        return filteredResults.map((emulationData) => {
            const actionName = emulationData.action.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/([A-Z])([A-Z][a-z])/g, '$1 $2').trim();
            return <ExpandableRow
                title={`Action: ${actionName}`}
                tableDom={expandableRowDom(emulationData)}
                titleSize='sm'
                className='mt-5'
                extraHeaderPosition={'end'}
                {...(emulationData?.interesting && { extraHeaderFields: ['interesting'] })}
                key={emulationData?.id}
                isExpandedByDefault={isAllExpanded}
            />;
        });
    }, [sandboxResources, isInteresting, isAllExpanded]);


    const ProcessDom = useMemo(() => {
        if (!sandboxResources || !sandboxResources.emulationData) {
            return (
                <div className="processesGraph">
                    <p className='h6Mask mt-5'>{t('Emulation Data')}</p>
                    <Col xs={12} sm={6} md={4} xl={3} className='storeProductPlaceholder' style={{ marginLeft: '-13px' }}>
                        <ContentLoader
                            height={400}
                            width={700}
                            speed={1}
                            backgroundColor="#EDEEF3"
                            foregroundColor="#f3f3f3"
                        >
                            <rect x="0" y="0" rx="1" ry="1" width="700" height="1200" />
                        </ContentLoader>
                    </Col>
                </div>
            );
        }

        return (
            <div className="processesGraph">
                <p className='h6Mask mt-5'>{t('Emulation Data')}</p>
                <ProcessesDiagram
                    sandboxResult={sandboxResources.emulationData}
                >
                </ProcessesDiagram>
            </div>
        );
    }, [sandboxResources]);

    const filtersDOM = useMemo(() => {
        return <Checkbox
            isSelected={isInteresting}
            onChange={() => setIsInteresting(!isInteresting)}
            useManualCheck
        >
            {'Interesting'}
        </Checkbox>;
    }, [isInteresting]);

    const emulationDataDom = useMemo(() => {
        if (!filtersDOM || !expandableTableDOM) {
            const minRows = 5;
            const maxRows = 12;
            const space = 10;
            const rowHeight = 30;
            const numRows = UtilsFunctions.getRandomInt(minRows, maxRows);
            let keyCounter = 0;

            return <ContentLoader
                className={'contentLoader'}
                speed={1}
                height={numRows * (30 + space)}
                width={'100%'}
                primaryColor='#EDEEF3'
                secondaryColor='#f3f3f3'>
                {Array(numRows).fill('').map((_value, index) => <rect key={keyCounter++} x='0' y={index * (rowHeight + space)} rx='4' ry='4' width={`${Math.random() * 70 + 30}%`} height={rowHeight} />)}
            </ContentLoader>;
        }

        return <>
            <Row>
                <Col xs={12} sm={5} md={6}>
                    <h6>{t('Emulation Data Results')}</h6>
                </Col>
                <Col xs={12} sm={7} md={6} className='isInteresting mt-sm-0 mt-4'>
                    {filtersDOM}
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <div className='colapseAllButton'>
                        <p className='mt-4'>{t('Emulation Data applies emulation and proprietary algorithms to overcome heavy obfuscation and extract key data (e.g. IOCs).')}</p>
                        <Button aria-label='ExpandAll' variant='outline-secondary' onClick={handleExpandAll}>{isAllExpanded ? t('Collapse All') : t('Expand All')}</Button>
                    </div>
                    {expandableTableDOM}
                </Col>
            </Row>
            <HorizontalTable data={[{ header: t('Type'), accessor: 'type' }]} />
        </>;
    });

    if (!ready) {
        return null;
    }

    return (
        <section className="processes">
            {ProcessDom}
            {emulationDataDom}
        </section>
    );
};

Processes.propTypes = {
    sandboxResources: PropTypes.object,
};

export default Processes;
