import { useState, useMemo } from 'react';
import { CopyButton } from '@mdc/ui';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import './ExpandableMeta.scss';

const ExpandableMeta = ({ title, meta, className = '' }) => {
    const { t, ready } = useTranslation();
    const [isMetaDisplayed, setIsMetaDisplayed] = useState(false);

    const handleShowMeta = (evt) => {
        evt.preventDefault();
        setIsMetaDisplayed(!isMetaDisplayed);
    };

    const expandableMetaBodyClassName = useMemo(() => classNames({
        'expanded': isMetaDisplayed
    }, 'expandableMetaBody'), [isMetaDisplayed]);

    if (!ready) {
        return;
    }

    return (
        <>
            <a href='/' onClick={handleShowMeta} variant='link' className={`metaButton coppyButton ${className}`}>{t('meta')}</a>
            <div className='coppyButton'>
                <CopyButton icon={'clone'} data={JSON.stringify(meta || title, null, 2)} />
            </div>
            {meta && (
                <div className={`metaContainer ${expandableMetaBodyClassName}`}>
                    <code dangerouslySetInnerHTML={{
                        __html: JSON.stringify(meta, null, 6)?.replace(/\n( *)/g, (match, p) => {
                            return '<br>' + '&nbsp;'.repeat(p.length);
                        })
                    }} />
                </div>
            )}
        </>
    );
};

export default ExpandableMeta;

ExpandableMeta.propTypes = {
    title: PropTypes.string,
    meta: PropTypes.object,
    className: PropTypes.bool
};
