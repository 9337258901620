import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextModal } from '@mdc/ui';

import './Image.scss';

const Image = ({ base64, label, variant }) => {
    const { t } = useTranslation();
    const [showImage, setShowImage] = useState(false);
    const image = <img src={`data:image/png;base64,${base64}`} alt='customImageModal' title='Snapshot' className='customImageModal' />;

    const handleExpand = () => {
        setShowImage(true);
    };

    const handleCollapse = () => {
        setShowImage(false);
    };

    const modal = (
        <TextModal
            show={showImage}
            onClose={handleCollapse}
            title={t('File Preview')}
            body={[{ value: image }]}
            className='customImageModal'
            size='xl'
        />
    );

    if (variant === 'url') {
        return (
            <div className='customImage'>
                <a href='#!' onClick={handleExpand}>
                    {label}
                </a>
                {modal}
            </div>
        );
    }

    return (
        <div className='customImage'>
            <button onClick={handleExpand} className='imageButton'>
                {image}
            </button>
            {modal}
        </div>
    );
};

Image.propTypes = {
    base64: PropTypes.string.isRequired,
    label: PropTypes.string,
    variant: PropTypes.string,
};

export default Image;
