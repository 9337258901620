/* eslint-disable camelcase */
import { useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { results, NotificationContext } from '@mdc/ui';
import { NOTIFICATION_TYPES } from '@mdc/constants';

import { useTranslation } from 'react-i18next';
import { dateTimeService } from '@mdc/services';

const Leaflet = typeof window !== 'undefined' ? require('leaflet') : null;
const MapContainer = typeof window !== 'undefined' ? require('react-leaflet').MapContainer : null;
const TileLayer = typeof window !== 'undefined' ? require('react-leaflet').TileLayer : null;
const Marker = typeof window !== 'undefined' ? require('react-leaflet').Marker : null;

import 'leaflet/dist/leaflet.css';

import './AddressLocation.scss';

const { FileOverview } = results;

const AddressLocation = ({ addressScanResult, resultsLoading }) => {
    const { t, i18n, ready } = useTranslation();
    const scanResults = addressScanResult?.geo_info || {};
    const { location } = scanResults || {};
    const { notify } = useContext(NotificationContext);

    if (Leaflet) {
        Leaflet.Icon.Default.imagePath = '../node_modules/leaflet';
        delete Leaflet.Icon.Default.prototype._getIconUrl;
        Leaflet.Icon.Default.mergeOptions({
            iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png').default,
            iconUrl: require('leaflet/dist/images/marker-icon.png').default,
            shadowUrl: require('leaflet/dist/images/marker-shadow.png').default
        });
    }

    const fileInfoDOM = useMemo(() => {
        const lang = i18n?.language?.split('-')[0] || 'en';

        let firstColumn = [];
        let secondColumn = [];

        if (!addressScanResult || !scanResults.location ||resultsLoading) {
            firstColumn = undefined;
            secondColumn = undefined;


        } else {
            const { city, country, subdivisions } = scanResults;

            if (!city && !country && !subdivisions) {
                notify({
                    message: t('Network error occurred, and your request could not be completed. Please refresh the page'),
                    type: NOTIFICATION_TYPES.CRITICAL
                });

                return;
            }

            const startTime = addressScanResult?.lookup_results?.start_time;

            firstColumn.push({
                'column': t('Country Name'),
                'data': country?.name || '-'
            });

            firstColumn.push({
                'column': t('Region Name'),
                'data': subdivisions[0]?.name || '-'
            });

            firstColumn.push({
                'column': t('City'),
                'data': city?.name || '-'
            });

            secondColumn.push({
                'column': t('Latitude'),
                'data': location?.latitude || '-'
            });

            secondColumn.push({
                'column': t('Longitude'),
                'data': location?.longitude || '-'
            });

            startTime && secondColumn.push({
                'column': t('Scanned'),
                'data': dateTimeService.getDateTimeString(startTime, lang) + ' ' + t('[ {{dateHumanized}} ago ]', { dateHumanized: dateTimeService.dateHumanized(startTime, lang) })
            });
        }
        if (!scanResults.location){
            return <Row class='noLocation'> {t('There is no information available about the location of this IP address.')} </Row>;
        }
        return <FileOverview title='Information' firstColumnData={firstColumn} lastColumnData={secondColumn}/>;


    }, [addressScanResult, scanResults, resultsLoading, t, i18n]);

    const leafletMapDOM = useMemo(() => {
        const position = [location?.latitude ?? 0, location?.longitude ?? 0];

        if (typeof window !== 'undefined') {
            if (!scanResults.location){
                return;
            }
            return <MapContainer
                center={position}
                zoom={4}
                dragging={false}
                doubleClickZoom={false}
                scrollWheelZoom={false}
                zoomControl={false}
                useFlyTo={true}
            >
                <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a>, &amp;copy <a href="https://carto.com/attribution">CARTO</a>'
                    url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
                />
                <Marker position={position}/>
            </MapContainer>;
        }

    }, [location, scanResults, resultsLoading]);

    if (!ready) {
        return;
    }

    return <>
        <section className='addressLocation'>
            {fileInfoDOM}
            <Row>
                <Col lg={12} md={12} xs={12} className='map'>
                    {leafletMapDOM}
                </Col>
            </Row>
        </section>
    </>;
};

AddressLocation.propTypes = {
    addressScanResult: PropTypes.object,
    resultsLoading: PropTypes.bool
};


export default AddressLocation;
