import { useMemo, useState } from 'react';
import { results, HorizontalTable, PlainTable, PDFExport } from '@mdc/ui';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ProactiveDlpData } from './ProactiveDlpData';
import ExpandableRow from '../dynamic-analysis/ExpandableRow';
import ContentLoader from 'react-content-loader';
import PropTypes from 'prop-types';
import { UtilsFunctions } from '@mdc/services';

import './ProactiveDLP.scss';

const { ScoreHeader } = results;

const ProactiveDLP = ({ results, resultsLoading, scanning, fileImage }) => {
    const extractedFiles = results?.extracted_files?.files_in_archive;
    const sanitizationResultsDetails = results?.process_info?.post_processing?.sanitization_details?.details;
    const fileInfo = results?.file_info?.display_name;
    const infoFile = results?.file_info;
    const scanResult = results?.scan_results;
    const scanResults = results?.dlp_info ? results.dlp_info : {};
    const dlpResults = scanResults?.hits;
    const [numberOfFinds, setNumberOfFinds] = useState(0);
    let finds = 0;
    const minRows = 5;
    const maxRows = 12;
    const space = 10;
    const rowHeight = 30;
    const { t, ready } = useTranslation();
    const numRows = UtilsFunctions.getRandomInt(minRows, maxRows);
    const objectOrder = {
        'ccn': null,
        'ssn': null,
        'ipv4': null,
        'cidr': null
    };

    const getBoolVal = (val) => {
        if (val && typeof val == 'boolean') {
            return <span className='lowClass font-weight-bold'>{t('Redacted')}</span>;
        }
        return <span className='highClass font-weight-bold'>{t('Not Redacted')}</span>;
    };

    const processRawData = (rawdata, meta) => meta.filter((row) => Object.hasOwn(rawdata, row.accessor))
        .map((row) => {
            let value;
            if (row.parser) {
                value = row.parser(rawdata[row.accessor]);
            } else if (typeof rawdata[row.accessor] === 'object') {
                value = rawdata[row.accessor].result;
            } else {
                value = rawdata[row.accessor];
            }
            return { field: row.header, data: value, shouldSanitizeHTML: false };
        });

    const hotizontalTableDom = useMemo(() => {
        if (!scanResults) {
            return;
        }

        return <HorizontalTable
            title='Full report from Proactive DLP'
            data={processRawData(scanResults, ProactiveDlpData(t).tableColumns)}
        />;
    }, [scanResults]);

    const aboutDlpDom = useMemo(() => <>
        {/*eslint-disable-next-line no-useless-escape*/}
        <p className='aboutDynamicAnalysis' dangerouslySetInnerHTML={{ __html: t('Read more about OPSWAT Data Loss Prevention technology <a href=\"https://www.opswat.com/technologies/proactive-data-loss-prevention\" target=\"_blank\">here</a>') }} />
    </>, []);

    const certaintyScore = (certainty) => {
        return <span className={`badge ${ProactiveDlpData(t).badgeColor?.[certainty]}`}>{certainty}</span>;
    };

    const hitsTableData = useMemo(() => {
        const initalHits = scanResults?.hits;
        const hits = Object.assign(objectOrder, initalHits);
        let keyCounter = 0;

        if (!scanResults || resultsLoading || scanning) {
            return <ContentLoader
                className={'contentLoader'}
                speed={1}
                height={numRows * (30 + space)}
                width={'100%'}
                style={{ paddingTop: '20px' }}
                primaryColor='#EDEEF3'
                secondaryColor='#f3f3f3'>
                {Array(numRows).fill('').map((_value, indexName) => <rect key={keyCounter++} x='0' y={indexName * (rowHeight + space)} rx='4' ry='4' width={`${Math.random() * 70 + 30}%`} height={rowHeight} />)}
            </ContentLoader>;
        }

        if (!scanResults?.hits) {
            return;
        }

        return Object.keys(hits)?.map((hit) => {
            hits[hit]?.hits.sort((a, b) => b.certainty_score - a.certainty_score);

            const palinTableData = [];

            hits[hit]?.hits?.forEach((item) => {
                finds += 1;
                palinTableData.push({ hit: item?.hit || '-', certaintyScore: item.certainty_score || '-', location: item.location || '-', after: item.after || '-', before: item.before || '-', certainty: certaintyScore(item.certainty) || '-', tryRedact: getBoolVal(item.tryRedact) || '-' });
            });

            setNumberOfFinds(finds);

            return hits[hit] && <ExpandableRow
                className='mt-5'
                key={hit}
                title={hits[hit]?.display_name.toUpperCase()}
                titleSize={'sm'}
                extraHeaderPosition={'end'}
                tableDom={<PlainTable columnsData={ProactiveDlpData(t).plainTableColums} data={palinTableData} hasSorting={false} />}
            />;
        });
    }, [scanResults, resultsLoading, scanning]);

    const scoreMetascanDom = useMemo(() => {
        const verdict = scanResults?.verdict;
        const isLoading = typeof verdict === 'undefined';
        return <ScoreHeader
            isProcessing={isLoading}
            category={t('Proactive DLP')}
            score={numberOfFinds}
            badge={ProactiveDlpData(t).verdictText[verdict]?.text}
            badgeColor={`badge ${ProactiveDlpData(t).verdictColor[verdict]}`}
            scoreColor={ProactiveDlpData(t).verdictColor[verdict]}
            processImgSrc={fileImage.processing.publicURL}
            isPercentage={false}
        />;

    }, [t, scanResults, fileImage, numberOfFinds]);

    if (!ready) {
        return null;
    }

    return <section className='proactiveDlp'>
        <Row>
            <Col>
                <PDFExport
                    aditionalData={infoFile}
                    data={scanResult}
                    types={['pdf']}
                    fileName={fileInfo}
                    results={results}
                    extractedFiles={extractedFiles}
                    sanitizationResultsDetails={sanitizationResultsDetails}
                    dlpResults={dlpResults}
                />
            </Col>
        </Row>
        <Row>
            <Col lg={4} md={4} xs={12}>
                {scoreMetascanDom}
                {aboutDlpDom}
            </Col>
            <Col lg={8} md={8} xs={12}>
                {hotizontalTableDom}
            </Col>
        </Row>
        <Row>
            <Col>
                {hitsTableData}
            </Col>
        </Row>
    </section>;
};

export default ProactiveDLP;

ProactiveDLP.propTypes = {
    results: PropTypes.object,
    resultsLoading: PropTypes.bool,
    scanning: PropTypes.string,
    fileImage: PropTypes.object.isRequired,
};

