export default (t) => {
    return [
        {
            header: t('Description'),
            accessor: 'description',
        },
        {
            header: t('Source'),
            accessor: 'source'
        },
        {
            header: t('Action'),
            accessor: 'action'
        },
        {
            header: t('Class'),
            accessor: 'Class'
        },
        {
            header: t('Path'),
            accessor: 'Path'
        },
        {
            header: t('ControlType'),
            accessor: 'ControlType',
        },
        {
            header: t('Element'),
            accessor: 'Element'
        },
        {
            header: t('Visible'),
            accessor: 'Visible',
            parser: (value) => value ? t('true') : t('false')
        },
        {
            header: t('Attribute'),
            accessor: 'Attribute'
        },
        {
            header: t('Content'),
            accessor: 'Content'
        },
        {
            header: t('OOXML'),
            accessor: 'OOXML',
            parser: (value) => value ? t('true') : t('false')
        },
        {
            header: t('SysKind'),
            accessor: 'SysKind'
        },
        {
            header: t('CodePage'),
            accessor: 'CodePage'
        },
        {
            header: t('LCID(suggestion)'),
            accessor: 'LCID(suggestion)'
        },
        {
            header: t('References'),
            accessor: 'References'
        },
        {
            header: t('CompilerConstants'),
            accessor: 'CompilerConstants'
        },
        {
            header: t('PCode'),
            accessor: 'PCode'
        },
        {
            header: t('Function'),
            accessor: 'Function'
        },
        {
            header: t('Comments'),
            accessor: 'Comments'
        },
        {
            header: t('URI'),
            accessor: 'URI'
        },
        {
            header: t('File'),
            accessor: 'File'
        },
        {
            header: t('IP'),
            accessor: 'IP'
        },
        {
            header: t('Region'),
            accessor: 'Region'
        },
        {
            header: t('Application Visible'),
            accessor: 'Application_Visible',
            parser: (value) => value ? t('true') : t('false')
        },
        {
            header: t('Pid'),
            accessor: 'Pid'
        },
        {
            header: t('ParentPid'),
            accessor: 'ParentPid'
        },
        {
            header: t('Command'),
            accessor: 'Command'
        },
        {
            header: t('Program'),
            accessor: 'Program'
        }
    ];
};
