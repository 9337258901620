export default (t) => {
    return [
        {
            Header: t('Result'),
            accessor: 'result'
        },
        {
            Header: t('File Path'),
            accessor: 'name'
        },
        {
            Header: t('Results'),
            accessor: 'scan'
        }
    ];
};
