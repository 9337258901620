import { forwardRef, useEffect } from 'react';
import { Row, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

const TableButton = forwardRef(
    ({ title, onClick, shouldBeExpanded, isDefaultClicked, isActive }, ref) => {
        useEffect(() => {
            if (isDefaultClicked) {
                onClick();
            }
        }, [onClick, isDefaultClicked]);

        return (
            <Row className={`buttonWithDetailsContainer btn ${shouldBeExpanded ? 'expanded' : ''}`}>
                <Button
                    ref={ref}
                    onClick={onClick}
                    variant="outline-secondary"
                    title={title}
                    className={`${isActive ? 'active' : ''}`}
                >
                    {title}
                </Button>
            </Row>
        );
    }
);

export default TableButton;

TableButton.propTypes = {
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    shouldBeExpanded: PropTypes.bool,
    isDefaultClicked: PropTypes.bool,
    isActive: PropTypes.bool
};
