
export default (t) => {
    return  [
        {
            Header: t('Name'),
            accessor: 'name'
        },
        {
            Header: t('Virtual address'),
            accessor: 'virtual_address'
        },
        {
            Header: t('Virtual size'),
            accessor: 'virtual_size'
        },
        {
            Header: t('Raw size'),
            accessor: 'raw_size'
        },
        {
            Header: t('Entropy'),
            accessor: 'entropyRounded'
        },
        {
            Header: t('MD5'),
            accessor: 'md5'
        }
    ];
};
