import { Tabs, Tab, Col, Row, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { useMemo, useState } from 'react';
import { results, PlainTable } from '@mdc/ui';
import { useTranslation } from 'react-i18next';
import ExpandableRow from '../../dynamic-analysis/ExpandableRow';
import PropTypes from 'prop-types';
import './Summary.scss';
import ContentLoader from 'react-content-loader';
import { getRandomID } from '@mdc/services';
import { UtilsFunctions } from '@mdc/services';

const { ScoreHeader } = results;

const tableColumns = [
    {
        Header: 'Tactic',
        accessor: 'tactic',
    },
    {
        Header: 'Technique',
        accessor: 'technique',
    },
];

const Summary = ({ sandboxResults, fileImage }) => {
    const [buttonTooltipId] = getRandomID();

    const [mitreTableDataTemplate, setMitreTableDataTemplate] = useState({});
    const { t, ready } = useTranslation();
    const [filterTechnique, setFilterTechnique] = useState(null);
    const [dataTemplateState, setDataTemplateState] = useState({
        MALICIOUS: [],
        LIKELY_MALICIOUS: [],
        SUSPICIOUS: [],
        INFORMATIONAL: [],
        UNKNOWN: [],
        BENIGN: []
    });

    let dataTemplate = {
        MALICIOUS: [],
        LIKELY_MALICIOUS: [],
        SUSPICIOUS: [],
        INFORMATIONAL: [],
        UNKNOWN: [],
        BENIGN: []
    };

    const getColorKey = {
        '-1': 'benign',
        0: 'unknow',
        0.05: 'unknow',
        0.1: 'informational',
        0.15: 'informational',
        0.2: 'informational',
        0.25: 'informational',
        0.3: 'informational',
        0.35: 'informational',
        0.4: 'suspicious',
        0.45: 'suspicious',
        0.5: 'suspicious',
        0.55: 'suspicious',
        0.6: 'suspicious',
        0.65: 'suspicious',
        0.7: 'likelyMalicious',
        0.75: 'likelyMalicious',
        0.8: 'likelyMalicious',
        0.85: 'likelyMalicious',
        0.9: 'malicious',
        0.95: 'malicious',
        1: 'malicious',
    };

    const scaleColorKey = (colorKey) => {
        const scaledColorKey = {};
        for (const key in colorKey) {
            const scaledKey = Math.round(Number(key) * 100).toString();
            scaledColorKey[scaledKey] = colorKey[key];
        }
        return scaledColorKey;
    };

    const resources = useMemo(() => {
        if (!sandboxResults || sandboxResults.status !== 'done') {
            return;
        }
        return sandboxResults.data;
    }, [sandboxResults]);

    const tableData = useMemo(() => {
        if (
            !resources?.allSignalGroups ||
            !Array.isArray(resources?.allSignalGroups)
        ) {
            return;
        }

        const dataStructure = resources?.allSignalGroups?.map((item) => {
            const verdict = item?.verdict?.verdict === 'LIKELY_MALICIOUS' ? 'MALICIOUS' : item?.verdict?.verdict;
            if (filterTechnique) {
                if (item?.allMitreTechniques?.some(t => t?.name === filterTechnique)) {
                    dataTemplate?.[verdict]?.push({
                        description: item.description,
                        signals: item.signals,
                        allMitreTechniques: item.allMitreTechniques,
                    });
                }
            } else {
                dataTemplate?.[verdict]?.push({
                    description: item.description,
                    signals: item.signals,
                    allMitreTechniques: item.allMitreTechniques,
                });
            }

            return dataTemplate;
        });

        setDataTemplateState(dataTemplate);

        return dataStructure;

    }, [resources, filterTechnique]);

    const generateMitreRows = (title, data) => {
        return (
            <div>
                <Row className="title">{title}</Row>
                {data.map((item) => {
                    return (
                        <Row className="signalRow" key={title}>
                            {item}
                        </Row>
                    );
                })}
            </div>
        );
    };

    const renderTooltip = (props, message) => (
        <Tooltip
            id={buttonTooltipId}
            {...props}
            className="sandboxExtractedTooltip"
        >
            {message}
        </Tooltip>
    );

    const generateTabContent = (data) => {
        const mitreTableData = {};
        return (
            data &&
            data.length !== 0 &&
            data.map((tactic) => {
                let miteData = [];
                let signalDataInput = [];
                let signalDataExtracted = [];

                tactic?.allMitreTechniques?.forEach((technique) => {
                    miteData.push({
                        tactic: technique.relatedTactic?.name,
                        technique: technique.name,
                    });
                    if (technique.relatedTactic?.name && technique.name) {
                        mitreTableData[technique.relatedTactic?.name] = [
                            ...(mitreTableData[technique.relatedTactic?.name] ||
                                []),
                            technique.name,
                        ];
                    }
                });

                tactic?.signals?.forEach((signal) => {
                    signal.originType === 'INPUT_FILE'
                        ? signalDataInput.push(signal.signalReadable)
                        : signalDataExtracted.push(signal.signalReadable);
                });

                const expandableRowTable = (
                    <div className="expandable">
                        {tactic?.allMitreTechniques && (
                            <PlainTable
                                columnsData={tableColumns}
                                data={miteData}
                            />
                        )}
                        {signalDataInput.length > 0 &&
                            generateMitreRows(
                                'Origin: Input file',
                                signalDataInput,
                            )}
                        {signalDataExtracted.length > 0 &&
                            generateMitreRows(
                                'Origin: Extracted file',
                                signalDataExtracted,
                            )}
                    </div>
                );
                return (
                    <ExpandableRow
                        key={tactic?.signals[0]?.originIdentifier}
                        title={tactic.description}
                        tableDom={expandableRowTable}
                        titleSize="md"
                        className="mt-5"
                        extraHeaderFields={[
                            <span className='badge' key={tactic.description}>
                                {tactic?.allMitreTechniques?.length}
                            </span>,
                            <span key={tactic?.signals[0]?.resource} className={`badge badge-${getColorKey[tactic?.signals[0]?.strength]}`}>
                                {(() => {
                                    const strength = getColorKey[tactic?.signals[0]?.strength];

                                    if (strength === 'informational') {
                                        return 'no threat'.toUpperCase();
                                    } else if (strength === 'likelyMalicious') {
                                        return 'likely malicious'.toUpperCase();
                                    } else {
                                        return strength?.toUpperCase();
                                    }
                                })()}
                            </span>

                        ]}
                    />
                );
            })
        );
    };

    const activeTabDom = Object.keys(dataTemplateState).map((item) => {
        const title = item;
        const arrayWithNoDuplicates = Array.isArray(dataTemplate[item]) &&
            dataTemplate[item].length !== 0 && [...new Map(
                dataTemplate[item].map((tactic) => {
                    return [tactic['description'], tactic];
                }),
            ).values(),
            ];

        arrayWithNoDuplicates && arrayWithNoDuplicates.forEach((tactic) => {
            tactic?.allMitreTechniques?.forEach((technique) => {
                if (technique.relatedTactic?.name && technique.name) {
                    setMitreTableDataTemplate((prevState) => ({
                        ...prevState,
                        [technique.relatedTactic?.name]: [
                            ...(prevState[technique.relatedTactic?.name] ||
                                []),
                            technique.name,
                        ],
                    }));
                }
            });
        });

        return (
            arrayWithNoDuplicates && (
                <Tab eventKey={item} title={title} key={item}>
                    {generateTabContent(arrayWithNoDuplicates)}
                </Tab>
            )
        );
    });

    const removeDuplicates = (myArr, prop) => {
        return myArr.filter((obj, pos, arr) => {
            return (
                arr
                    .map((mapObj) => mapObj[prop].name)
                    .indexOf(obj[prop].name) === pos
            );
        });
    };

    const tagsDom = useMemo(() => {
        if (!resources) {
            const minRows = 5;
            const maxRows = 12;
            const space = 10;
            const rowHeight = 30;
            const numRows = UtilsFunctions.getRandomInt(minRows, maxRows);
            let keyCounter = 0;

            return (
                <ContentLoader
                    className={'contentLoader'}
                    speed={1}
                    height={numRows * (30 + space)}
                    width={'100%'}
                    primaryColor="#EDEEF3"
                    secondaryColor="#f3f3f3"
                >
                    {Array(numRows)
                        .fill('')
                        .map((_value, index) => (
                            <rect
                                key={keyCounter++}
                                x="0"
                                y={index * (rowHeight + space)}
                                rx="4"
                                ry="4"
                                width={`${Math.random() * 70 + 30}%`}
                                height={rowHeight}
                            />
                        ))}
                </ContentLoader>
            );
        }

        const sortedArray = removeDuplicates(resources.allTags, 'tag');
        return sortedArray.map((item) => {
            const description = item.tag?.descriptions[1]?.description;
            return (
                <div key={item?.tag.name} className="tag-container">
                    <div className="tag" key={item?.tag.name}>
                        <div className="badge">
                            {item.tag.name}
                            {description !== undefined &&
                                description?.length !== 0 && (
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={(props) =>
                                            renderTooltip(props, description)
                                        }
                                        trigger={'hover'}
                                        tooltip-inner
                                        rootClose
                                    >
                                        <i className="icon icon-info-circled"></i>
                                    </OverlayTrigger>
                                )}
                        </div>
                    </div>
                </div>
            );
        });
    }, [resources]);

    const mitreTableDom = useMemo(() => {
        if (
            !mitreTableDataTemplate ||
            Object.keys(mitreTableDataTemplate).length === 0
        ) {
            return;
        }

        let mitreArray = [];
        Object.keys(mitreTableDataTemplate).forEach((item) => {
            const badge = (
                <div>
                    {[...new Set(mitreTableDataTemplate[item])].map(
                        (technique, index) => {
                            return (
                                <Button
                                    key={`${item}-${index}`}
                                    className={`badge informational ${filterTechnique === technique ? 'buttonActive' : ''}`}
                                    variant='outline-secondary'
                                    onClick={() => (filterTechnique === technique) ? setFilterTechnique(null) : setFilterTechnique(technique)}
                                >
                                    {technique}
                                </Button>
                            );
                        },
                    )}
                </div>
            );

            mitreArray.push({ tactic: item, technique: badge });
        });
        return (
            <PlainTable
                columnsData={tableColumns}
                data={mitreArray}
            ></PlainTable>
        );
    }, [mitreTableDataTemplate, filterTechnique]);

    const aboutDynamicAnalysisDom = useMemo(
        () => (
            <>
                {/*eslint-disable-next-line no-useless-escape*/}
                <p
                    className="aboutDynamicAnalysis"
                    dangerouslySetInnerHTML={{
                        __html: t(
                            'Read more about OPSWAT Filescan Sandbox technology <a href="https://docs.opswat.com/filescan" target="_blank">here</a>',
                        ),
                    }}
                />
            </>
        ),
        [],
    );

    const ScoreHeaderDom = useMemo(() => {
        const sandboxScoreProcessed = sandboxResults?.results?.score;
        const scaledGetColorKey = scaleColorKey(getColorKey);
        return (
            <ScoreHeader
                isProcessing={!resources}
                category={t('Summary')}
                score={resources?.finalVerdict?.threatLevel * 100}
                badge={resources?.finalVerdict?.verdict.replace('_', ' ')}
                badgeColor={scaledGetColorKey[sandboxScoreProcessed]}
                scoreColor={scaledGetColorKey[sandboxScoreProcessed]}
                processImgSrc={fileImage.processing.publicURL}
                isPercentage={true}
            />
        );
    }, [resources]);

    const ActiveTabDom = useMemo(() => {
        if (!tableData) {
            return;
        }

        return (
            <Tabs id="tab" className="mb-3">
                {activeTabDom}
            </Tabs>
        );
    }, [tableData, filterTechnique]);

    const MitreTableDom = useMemo(() => {
        if (!mitreTableDom) {
            return;
        }

        return (
            <Col>
                <Row>
                    <p className='mitre'>{t('MITRE Techniques')}</p>{mitreTableDom}
                </Row>
            </Col>
        );
    }, [mitreTableDom]);

    if (!ready) {
        return null;
    }

    return (
        <section className="summaryPage">
            <Row className="rowSection">
                <Col lg={4} md={4} xs={12}>
                    {ScoreHeaderDom}
                    {aboutDynamicAnalysisDom}
                </Col>
                <Col lg={8} md={8} xs={12} className="summaryDataWrapper">
                    <div>{tagsDom}</div>
                </Col>
            </Row>
            {ActiveTabDom}
            {MitreTableDom}
        </section>
    );
};

Summary.propTypes = {
    sandboxResults: PropTypes.Object,
    fileImage: PropTypes.object.isRequired,
};

export default Summary;
