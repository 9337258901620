/* eslint-disable no-useless-escape */
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { PlainTable, HorizontalTable } from '@mdc/ui';
import './ApkData.scss';
import APK_DATA from './ApkManifestData';
import dangerous from './PermissionList';
import ContentLoader from 'react-content-loader';


const ApkData = ({ apkInfoResults, scanning, resultsLoading }) => {
    const { t, ready } = useTranslation();
    let permissionTableData = [];
    let packageData = apkInfoResults?.package ? [{
        field: 'Package',
        data: apkInfoResults?.package
    }] : [];

    let versionsData = apkInfoResults ? [
        {
            field: 'versionName',
            data: apkInfoResults.versionName || '-'
        },
        {
            field: 'versionCode',
            data: apkInfoResults.versionCode || '-'
        }
    ] : [];

    let sdkData = apkInfoResults?.usesSdk ? [
        {
            field: 'targetSdkVersion',
            data: apkInfoResults.usesSdk.targetSdkVersion || '-'
        },
        {
            field: 'minSdkVersion',
            data: apkInfoResults.usesSdk.minSdkVersion || '-'
        }
    ] : [];


    const tableColumns = [
        {
            Header: '',
            accessor: 'permission'
        },
        {
            Header: 'Meta',
            accessor: 'meta'
        }
    ];

    const levelOfProtection = useMemo(() => ({
        0: t('Normal'),
        1: <span className="signature">{t('Signature')}</span>,
        2: <span className="dangerous">{t('Dangerous')}</span>,
        3: <span className="dangerous">{t('signatureOrSystem')}</span>,
    }), [t]);

    const installLocations = {
        0: 'internalOnly',
        1: 'auto',
        2: 'preferExternal'
    };

    const aboutApkDataDom = (
        <div className='aboutApk'>
            <h6 className='category'>{t('Android Metadata')}</h6>
            <p dangerouslySetInnerHTML={{ __html: t('Android apps have the potential of being malicious if they require dangerous permissions or intent filter combinations. The information on this page is extracted from the AndroidManifest.xml file (static analysis), and dangerous permissions are highlighted with red. For more details see the <a href=\"https://docs.opswat.com/mdcloud/operation/scan-result-page\" target=\"_blank\" rel=\"noopener noreferrer\">documentation</a>') }} />
        </div>
    );

    const createExpandedRowsFilters = (data, title) => {

        const meta = {
            expandable: true,
            data: {
                custom: []
            }
        };

        data.forEach((item) => {
            let intentFilters = '';
            if (item.intentFilters && item.intentFilters.length) {
                intentFilters = item.intentFilters[0]?.actions[0]?.name;
            }

            const custom = <tr key={item.id} className='expandedCellWrapper'>
                <td colSpan='100%' className='expandedCell'>
                    <table className="filtersTable">
                        <tbody>
                            <tr>
                                <td className='tdCell'>{t('Name')}</td> <td className="tdCellData">{item.name}</td>
                            </tr>
                            {
                                item.permission && <tr>
                                    <td className='tdCell'>{t('Permission')}</td>
                                    <td className="tdCellData">{item.permission}</td>
                                </tr>
                            }
                            {
                                intentFilters && <tr>
                                    <td className='tdCell'>{t('IntentFilter')}</td>
                                    <td className="tdCellData">{item.intentFilters[0]?.actions[0]?.name}</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </td>
            </tr>;


            meta.data.custom.push(custom);
        });
        permissionTableData.push({ permission: <span key={Math.random()} className="cellTitle">{title}</span>, meta });
    };

    const createExpandedRows = (data, title) => {
        const meta = {
            expandable: true,
            data: {
                tableData: [],
            }
        };

        data.forEach((item) => {
            const tableData = {
                header: 'Name',
                value: item.name,
            };

            meta.data.tableData.push(tableData);
        });
        permissionTableData.push({ permission: <span key={Math.random()} className="cellTitle">{title}</span>, meta });
    };

    const createExpandedRowsNoHeader = (data, title) => {
        const meta = {
            expandable: true,
            data: {
                tableData: [],
            }
        };

        data.forEach((item) => {
            if (!item.name) {
                return null;
            }
            const permission = item.name.split('.');
            const name = dangerous.includes(permission[2]) ? <span className="dangerous">{item.name}</span> : item.name;
            const tableData = {
                header: '',
                value: name,
            };
            meta.data.tableData.push(tableData);
        });

        permissionTableData.push({ permission: <span key={Math.random()} className="cellTitle">{title}</span>, meta });
    };

    const informationTableData = useMemo(() => {
        let infoData;

        if (apkInfoResults) {
            infoData = apkInfoResults?.installLocation && apkInfoResults?.supportsScreens ? [
                apkInfoResults?.installLocation ? {
                    field: 'installLocation',
                    data: <span className="box"> {installLocations[apkInfoResults?.installLocation]} </span>
                } : {},
                apkInfoResults?.supportsScreens ? {
                    field: 'supportsScreens',
                    data: typeof apkInfoResults?.supportsScreens === 'object' ?
                        Object.keys(apkInfoResults?.supportsScreens).map((key) => <span key={Math.random()} className="box"> {key} </span>) :
                        <span className="box"> {apkInfoResults?.supportsScreens} </span>
                } : {}
            ] : [];

        }
        return (
            <div className="information">
                <HorizontalTable title='Information' data={packageData} />
                <Row>
                    <Col md={6} className="versionTable">
                        <HorizontalTable data={versionsData} />
                    </Col>
                    <Col md={6} className="versionTable" >
                        <HorizontalTable data={sdkData} />
                    </Col>
                </Row>
                <HorizontalTable data={infoData} />
            </div>
        );

    }, [apkInfoResults, packageData, versionsData, sdkData]);

    const expandedTable = useMemo(() => {
        return apkInfoResults && apkInfoResults?.permissions && apkInfoResults?.permissions?.map((item) => {
            item.protection = levelOfProtection[item.protectionLevel] || '-';
            item.permissionGroup = item.permissionGroup || '-';
            if (!item.name) {
                return null;
            }
            return item;
        });
    }, [apkInfoResults, levelOfProtection]);

    const processedTableData = useMemo(() => {
        const meta = expandedTable?.length && {
            expandable: true,
            data: {
                custom: (
                    <tr>
                        <td colSpan='100%' className="tableSection">
                            <PlainTable
                                columnsData={APK_DATA(t)}
                                data={expandedTable}
                            />
                        </td>
                    </tr>
                )
            }
        };

        meta && permissionTableData.push({ permission: <span key={'Permission'} className="cellTitle">{t('Permission')}</span>, meta });

        apkInfoResults?.usesPermissions && apkInfoResults?.usesPermissions?.length &&
            createExpandedRowsNoHeader(apkInfoResults?.usesPermissions, t('usesPermissions'));

        apkInfoResults?.application?.activities && apkInfoResults?.application?.activities?.length &&
            createExpandedRowsNoHeader(apkInfoResults?.application?.activities, t('Activities'));

        apkInfoResults?.application?.services && apkInfoResults?.application?.services?.length &&
            createExpandedRowsFilters(apkInfoResults?.application?.services, t('Services'));

        apkInfoResults?.application?.receivers && apkInfoResults?.application?.receivers?.length &&
            createExpandedRowsFilters(apkInfoResults?.application?.receivers, t('Receivers'));

        apkInfoResults?.application?.providers && apkInfoResults?.application?.providers?.length &&
            createExpandedRowsFilters(apkInfoResults?.application?.providers, t('Providers'));

        apkInfoResults?.application?.usesFeatures && apkInfoResults?.application?.usesFeatures?.length &&
            createExpandedRows(apkInfoResults?.application?.usesFeatures, t('usesFeatures'));

        return permissionTableData;

    }, [apkInfoResults, expandedTable, t]);

    const permissionsTableDom = useMemo(() => {
        if (!processedTableData || resultsLoading || scanning) {
            const space = 10;
            const rowHeight = 15;
            const numRows = 4;
            let keyCounter = 0;
            return <ContentLoader
                className={'contentLoader'}
                speed={1}
                height={numRows * (30 + space)}
                width={'50%'}
                style={{ paddingTop: '20px' }}
                primaryColor='#EDEEF3'
                secondaryColor='#f3f3f3'
            >
                {Array(numRows).fill('').map((_value, index) => (
                    <rect key={keyCounter++} x='0' y={index * (rowHeight + space)} rx='4' ry='4' width={`${Math.random() * 70 + 30}%`} height={rowHeight} />
                ))}
            </ContentLoader>;
        }

        return <PlainTable
            columnsData={tableColumns}
            data={processedTableData}
            hasCollapseAll={true}
            shouldExpandAll={true}
            isEntireRowExpandable={true}
        />;

    }, [tableColumns, processedTableData, resultsLoading, scanning]);


    if (!ready) {
        return null;
    }

    return (
        <section className='apkData'>
            <Row>
                <Col lg={3} md={3} xs={12}>
                    {aboutApkDataDom}
                </Col>
                <Col lg={9} md={9} xs={12}>
                    {informationTableData}
                    <Row className='permissionTable'> {permissionsTableDom} </Row>
                </Col>
            </Row>
        </section>

    );
};

ApkData.propTypes = {
    apkInfoResults: PropTypes.object,
    resultsLoading: PropTypes.bool,
    scanning: PropTypes.string,
};

export default ApkData;
