export default (t) => {
    return [
        {
            Header: t('Scan Date'),
            accessor: 'scanDate',
        },
        {
            Header: t('Result'),
            accessor: 'result',
        }
    ];
};
