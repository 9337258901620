export default (t) => {
    return [
        {
            Header: t('Protection level'),
            accessor: 'protection'
        },
        {
            Header: t('Group'),
            accessor: 'permissionGroup'
        },
        {
            Header: t('Name'),
            accessor: 'name'
        },
    ];
};
