export const iocsData = (t) => {
    return [
        {
            name: t('Urls'),
            value: 'extractedUrls'
        },
        {
            name: t('Domains'),
            value: 'extractedDomains'
        },
        {
            name: t('Emails'),
            value: 'extractedEmails'
        },
        {
            name: t('MD5'),
            value: 'extractedHashesMD5'
        },
        {
            name: t('SHA1'),
            value: 'extractedHashesSHA1'
        },
        {
            name: t('SHA-256s'),
            value: 'extractedHashesSHA256',
        },
        {
            name: t('SHA-512s'),
            value: 'extractedHashesSHA512',
        },
        {
            name: t('UUIDs'),
            value: 'extractedUUIDs',
        },
        {
            name: t('Registry Pathways'),
            value: 'extractedRegistryPathways',
        },
        {
            name: t('Revision Save IDs'),
            value: 'extractedRevisionSaveIDs',
        },
        {
            name: t('IPs'),
            value: 'extractedIPs',
        }
    ];
};

export const tableColumns = (t) => {
    return [
        {
            Header: t('IOC'),
            accessor: 'ioc'
        },
        {
            Header: t('Verdict'),
            accessor: 'verdict'
        },
        {
            Header: t('Origin'),
            accessor: 'origin'
        },
    ];
};
